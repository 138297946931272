import React, { useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import Footer from "../../shared/Footer";
import "./style.css";
import { useQuery } from "@tanstack/react-query";
import {
  getHeaderInformation,
  getEnvTrend,
  getDistByProg,
  getDistByOwnership,
  getDistByCommodityType,
  getDistByMode,
  getDisposalAmount,
  getRecentReceives,
  getRecentIssued,
  getPendingOrders,
  getStockStatus,
  getGITByInvoice,
  getInvoice,
  getFacilityCount,
} from "../../../Services/locationRequest";
// import {
//   getHeaderInformation,
//   getProgramItemsNation,
// } from "../../../Services/ApiManager";

import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import CustomCard from "../../shared/Shared Utils/CustomCards";
import {
  getStockOnHand,
  // getStockStatus,
} from "../../../Services/tableRequest";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbar,
} from "@mui/x-data-grid";
import CustomBarChart from "../../shared/Charts/CustomBarChart";
import styled from "styled-components";

import { getEnvironemntList } from "../../../Services/ApiManager";
import { Divider } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import {
  recentIssueColumn,
  recentReceiveColumn,
  pendingOrderColumn,
  stockStatusColumn,
  invoiceColumn,
  GITByInvoiceColumn,
  facillityCountColumn,
  PENDING_DETAIL_COLUMN,
  GIT_DETAIL_COLUMN,
  INVOICES_DETAIL_COLUMN,
} from "./LocationsTableColumns";
import { HUB } from "../../shared/Shared Utils/InputConstants";
import "react-datepicker/dist/react-datepicker.css";
//import "./style.css";
import { parse } from "date-fns";
import CustomBarChartV2 from "../../shared/Charts/CustomBarChartV2";
import GroupedLineChart from "../../shared/Charts/GroupedLineChart";
import CustomPieChart from "../../shared/Charts/CustomPieChart";
import { FACILITY_COUNT_API, GIT_BY_INVOICE_API, GIT_DETAIL_API, INVOICE_API, INVOICE_DETAIL_API, PENDING_DETAIL_API, PENDING_ORDER_API, RECENT_ISSUES_API, RECENT_RECEIVES_API, STOCK_STATUS_LOCATION_API } from "../../shared/Shared Utils/AppData";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import ChartWrapper from "../../shared/Charts/ChartWrapper";
import { parseGridColumns, parseGridRows } from "../../../Utils/ChartConverter";
import PieChartWrapper from "../../shared/Charts/PieChartWrapper";
import itmPic from "../../../assets/cardIcons/items.svg";
import fctPic from "../../../assets/cardIcons/facilities.svg";
import sohPic from "../../../assets/cardIcons/soh.svg";
import gitPic from "../../../assets/cardIcons/git.svg";
import expPic from "../../../assets/cardIcons/expired.svg";
import dmgPic from "../../../assets/cardIcons/damaged.svg";
import gapPic from "../../../assets/cardIcons/gap.svg";
import ovrgPic from "../../../assets/cardIcons/overage.svg";
import moment from "moment";
import { toEthiopian } from "ethiopian-date";
import { toEthiopianDateString } from "gc-to-ethiopian-calendar";
import { YEAR_SELECT_CONFIG } from "../../shared/Shared Utils/DataTableConstants";
import TreemapChart from "../../shared/Charts/TreeMap";
import { CustomDetailsModal } from "../../shared/Shared Utils/CustomDetailsModal";
import { useFanosStore } from "../../shared/Shared Utils/store";

export default function Locations() {
  const history = useNavigate();
  const location = useLocation();
  let date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${month}/${day}/${year}`;
  // let { ProgramName } = useParams();
  const locationCode = location.state.EnvironmentCode;
  const {data: { data: { Data:  [headerInfo] }} = { data: { Data: [] }}, isFetching: headerInfoLoading} = useQuery({
    queryKey: ['headerInfo', locationCode],
    queryFn: () => getHeaderInformation(locationCode),
    staleTime: 10800000,
  });
  const [envTrendParams, setEnvTrendParams] = useState({FiscalYear: 2016});
  const {data: { data: { Data:  envTrend }} = { data: { Data: [] }}, isFetching: envTrendLoading} = useQuery({
    queryKey: ['envTrend', locationCode, envTrendParams],
    queryFn: () => getEnvTrend(locationCode, envTrendParams),
    staleTime: 10800000,
  });
  const [disposalAmountParams, setDisposalAmountParams] = useState({EnvironmentCode: locationCode, From: moment().subtract(3, 'months').format("MM/DD/YYYY"), To: moment().format("MM/DD/YYYY")});
  const [disposalAmountParsed, setDisposalAmountParsed] = useState([]);
  const {data: { data: { Data:  disposalAmount }} = { data: { Data: [] }}, isFetching: disposalAmountLoading} = useQuery({
    queryKey: ['disposalAmount', disposalAmountParams],
    queryFn: () => getDisposalAmount(disposalAmountParams),
    staleTime: 10800000,
  });
  const [facilityCountParams, setFacilityCountParams] = useState({EnvironmentCode: locationCode});
  const {data: { data: { Data:  facilityCount }} = { data: { Data: [] }}, isFetching: facilityCountLoading} = useQuery({
    queryKey: ['facilityCount', facilityCountParams],
    queryFn: () => getFacilityCount(facilityCountParams),
    staleTime: 10800000,
  });
  const [pieDataParams, setPieDataParams] = useState({EnvironmentCode: locationCode, Year: 2016});
  const [pieDataKey, setPieDataKey] = useState('program');
  const [pieData, setPieData] = useState([]);
  const [pieDataLoading, setPieDataLoading] = useState(false);
  const [envCode, setEnvCode] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  const locationPendingModalOpen = useFanosStore((state) => state.locationPendingModalOpen);
  const modalLocationPending = useFanosStore((state) => state.modalLocationPending);
  const setLocationPendingModalOpen = useFanosStore((state) => state.setLocationPendingModalOpen);
  const locationGitModalOpen = useFanosStore((state) => state.locationGitModalOpen);
  const modalLocationGit = useFanosStore((state) => state.modalLocationGit);
  const setLocationGitModalOpen = useFanosStore((state) => state.setLocationGitModalOpen);
  const locationInvoiceModalOpen = useFanosStore((state) => state.locationInvoiceModalOpen);
  const modalLocationInvoice = useFanosStore((state) => state.modalLocationInvoice);
  const setLocationInvoiceModalOpen = useFanosStore((state) => state.setLocationInvoiceModalOpen);

  const headerName = location.state.Environment;

  useQuery({
    queryKey: ["Hub List"],
    queryFn: getEnvironemntList,
    onSuccess: (data) => {
      setEnvCode(data.data.Data);
    },
  });

  useEffect(() => {
    if(pieDataKey){
      handleChange(pieDataKey);
    }
  }, [pieDataParams]);

  useEffect(() => {
    if(disposalAmount.length){
      setDisposalAmountParsed(getDisposalChartData(disposalAmount));
    }
  }, [disposalAmount]);

  const getPieChartData = (rawArray) => {
    const raw = rawArray[0];
    return Object.keys(raw).filter((rf) => rf.endsWith('InETB')).map((rm) => 
      {
      return {
          InETB: rm.replace('InETB', ''),
          Amount: raw[rm]
        }
      });
  }

  const getDisposalChartData = (rawArray) => {
    const raw = rawArray[0];
    return Object.keys(raw).filter((rf) => rf.match('HPR') || rf.match('RDF')).map((rm) => 
      {
      return {
          InETB: rm,
          Amount: raw[rm]
        }
      });
  }

  function loadFilterToDefaultParams(filterParams, dateFilterFlag) {
    if (dateFilterFlag.hasDataFilter) {
      const cd = new Date();
      const currentDate = `${cd.getMonth()+1}/${cd.getDate()}/${cd.getFullYear()}`
      const sd = new Date((new Date()).setMonth((new Date()).getMonth()-1));
      const startDate = `${sd.getMonth()+1}/${sd.getDate()}/${sd.getFullYear()}`
      filterParams["From"] = startDate;
      filterParams["To"] = currentDate;
    }
    if (dateFilterFlag.hasDateRangeFilter) {
      const start = dateFilterFlag.startDate || moment().subtract(1, 'months').format("YYYY-MM-DD");
      const end = moment().format("YYYY-MM-DD");
      filterParams["From"] = start;
      filterParams["To"] = end;
    }
    filterParams["EnvironmentCode"] = locationCode;
    return filterParams;
  }

  useEffect(() => {
    async function fetchData() {
      setPieDataLoading(true)
      getDistByProg(pieDataParams).then((data) => {
        setPieDataLoading(false);
        setPieData(getPieChartData(data.data.Data));
      });
    }
    fetchData();
  }, []);

  const handleChange = (key) => {
    setPieDataKey(key);
    if(key === "program") {
      setPieDataLoading(true);
      getDistByProg(pieDataParams).then((data) => {  
        setPieDataLoading(false);
        setPieData(getPieChartData(data.data.Data));
      });
    }
    if(key === "ownershipType") {
      setPieDataLoading(true);
      getDistByOwnership(pieDataParams).then((data) => {
        setPieDataLoading(false);
        setPieData(getPieChartData(data.data.Data));
      });
    }
    if(key === "commodity") {
      setPieDataLoading(true);
      getDistByCommodityType(pieDataParams).then((data) => {
        setPieDataLoading(false);
        setPieData(getPieChartData(data.data.Data));
      });
    }
    if(key === "mode") {
      setPieDataLoading(true);
      getDistByMode(pieDataParams).then((data) => {
        setPieDataLoading(false);
        setPieData(getPieChartData(data.data.Data));
      });
    }
    // key === "disposalAmount" &&
    //   getDisposalAmount(pieDataParams).then((data) => {
    //     setPieData(getDisposalChartData(data.data.Data));
    //   });
    // key === "manufacturer" &&
    //   getItemByManufacturer(locationCode, event.target.value).then((data) => {
    //     setItemByManufacturer(data.data.Data);
    //   });
    // key === "country" &&
    //   getItemCountry(locationCode, event.target.value).then((data) => {
    //     setItemCountry(data.data.Data);
    //   });
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={{ color: "#15616d" }} />
        <GridToolbarFilterButton style={{ color: "#15616d" }} />
        {/* <GridToolbarDensitySelector style={{ color: "#15616d" }} /> */}
        <GridToolbarExport style={{ color: "#15616d" }} />
      </GridToolbarContainer>
    );
  }

  return (
    <Wrapper>
      <NavBar title={`Fanos ${headerName} Program Dashboard`} />
      {/* <div
        style={{
          textAlign: "center",

          padding: "1rem",
          color: "white",
          display: "flex",
          flexDirection: "row",
          background: "#15616d",
        }}
      >
        <Button
          style={{ color: "white" }}
          onClick={() => {
            history("/");
          }}
        >
          Home
        </Button>
        <div
          style={{
            textAlign: "center",
            marginLeft: " 20rem",
          }}
        >
          <h4>Fanos {headerName} Dashboard</h4>
        </div>
      </div> */}
      <div className="container-context">

        <div style={{ padding: "2rem 4rem" }}>
          <div>
            <div style={cardGroup}>
              <div class="flex-row">
                <h6 style={{ margin: "auto", paddingRight: "2rem" }}>HPR- </h6>
                <CustomCard
                  widget_config={{
                    title: "Items",
                    value: headerInfo
                      ? Math.round(headerInfo.NoOfItemsHPR).toLocaleString()
                      : 0,
                    logo_url: itmPic,
                  }}
                />
                <CustomCard
                  widget_config={{
                    title: "SOH",
                    value: headerInfo
                      ? Math.round(headerInfo.SOHValueHPR).toLocaleString()
                      : 0,
                    logo_url: sohPic,
                  }}
                />
                <CustomCard
                  widget_config={{
                    title: "GIT",
                    value: headerInfo
                      ? Math.round(headerInfo.GValueHPR).toLocaleString()
                      : 0,
                    logo_url: gitPic,
                  }}
                />
                <CustomCard
                  widget_config={{
                    title: "Expired",
                    value: headerInfo
                      ? Math.round(headerInfo.EValueHPR).toLocaleString()
                      : 0,
                    logo_url: expPic,
                  }}
                />
                <CustomCard
                  widget_config={{
                    title: "Facilities",
                    value: headerInfo
                      ? Math.round(
                          headerInfo.FacilityServedHPR
                        ).toLocaleString()
                      : 0,
                   logo_url: fctPic,
                  }}
                />
                <CustomCard
                  widget_config={{
                    title: "Damaged",
                    value: headerInfo
                      ? Math.round(headerInfo.DValueHPR).toLocaleString()
                      : 0,
                    logo_url: dmgPic,
                  }}
                />
                <CustomCard
                  widget_config={{
                    title: "Gap",
                    value: headerInfo
                      ? Math.round(headerInfo.GapValueHPR).toLocaleString()
                      : 0,
                    logo_url: gapPic,
                  }}
                />
                <CustomCard
                  widget_config={{
                    title: "Overage",
                    value: headerInfo
                      ? Math.round(headerInfo.OverageValueHPR).toLocaleString()
                      : 0,
                    logo_url: ovrgPic,
                  }}
                />
              </div>
            </div>
            <div style={cardGroup}>
              <div class="flex-row">
                <h6 style={{ margin: "auto", paddingRight: "2rem" }}>RDF-</h6>
                <CustomCard
                  widget_config={{
                    title: "Items",
                    value: headerInfo
                      ? Math.round(headerInfo.NoOfItemsRDF).toLocaleString()
                      : 0,
                    logo_url: itmPic,
                  }}
                />
                <CustomCard
                  widget_config={{
                    title: "SOH",
                    value: headerInfo
                      ? Math.round(headerInfo.SOHValueRDF).toLocaleString()
                      : 0,
                    logo_url: sohPic,
                  }}
                />
                <CustomCard
                  widget_config={{
                    title: "GIT",
                    value: headerInfo
                      ? Math.round(headerInfo.GValueRDF).toLocaleString()
                      : 0,
                    logo_url: gitPic,
                  }}
                />
                <CustomCard
                  widget_config={{
                    title: "Expired",
                    value: headerInfo
                      ? Math.round(headerInfo.EValueRDF).toLocaleString()
                      : 0,
                    logo_url: expPic,
                  }}
                />
                <CustomCard
                  widget_config={{
                    title: "Facilities",
                    value: headerInfo
                      ? Math.round(
                          headerInfo.FacilityServedRDF
                        ).toLocaleString()
                      : 0,
                    logo_url: fctPic,
                  }}
                />
                <CustomCard
                  widget_config={{
                    title: "Damaged",
                    value: headerInfo
                      ? Math.round(headerInfo.DValueRDF).toLocaleString()
                      : 0,
                    logo_url: dmgPic,
                  }}
                />
                <CustomCard
                  widget_config={{
                    title: "Gap",
                    value: headerInfo
                      ? Math.round(headerInfo.GapValueRDF).toLocaleString()
                      : 0,
                    logo_url: gapPic,
                  }}
                />
                <CustomCard
                  widget_config={{
                    title: "Overage",
                    value: headerInfo 
                      ? Math.round(headerInfo.OverageValueRDF).toLocaleString()
                      : 0,
                    logo_url: ovrgPic,
                  }}
                />
              </div>
            </div>
          </div>

          <Divider />
          <GridContainer>
            <LongChartWrapper>
              <ChartWrapper handleChange={handleChange} title="Issues & Receives Trend- Health Program & RDF" wgInfoId="wg-location-recieve-trend" parameter={envTrendParams} setParameter={setEnvTrendParams} isLoading={envTrendLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]}>
                <GroupedLineChart
                  data={envTrend}
                  label={[
                    "Received HPR",
                    "Issued HPR",
                    "Received RDF",
                    "Issued RDF",
                  ]}
                  fields={[
                    "AmountHPRInETB",
                    "AmountIssuedHPRInETB",
                    "AmountRDFInETB",
                    "AmountIssuedRDFInETB",
                  ]}
                  style={{
                    padding: "2rem",
                    position: "relative",
                    height: "24rem",
                    background: "White",
                  }}
                  yTitle="Birr Amount"
                  wgInfoId='wg-location-recieve-trend'
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(envTrend, ["ReceivedDate", "AmountHPRInETB", "AmountIssuedHPRInETB", "AmountRDFInETB", "AmountIssuedRDFInETB",])}
                  columns={parseGridColumns(["ReceivedDate", "AmountHPRInETB", "AmountIssuedHPRInETB", "AmountRDFInETB", "AmountIssuedRDFInETB",])}
                  getRowId={(row) => row.ReceivedDate }
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <GroupedLineChart
                  data={envTrend}
                  label={[
                    "Received HPR",
                    "Issued HPR",
                    "Received RDF",
                    "Issued RDF",
                  ]}
                  fields={[
                    "AmountHPRInETB",
                    "AmountIssuedHPRInETB",
                    "AmountRDFInETB",
                    "AmountIssuedRDFInETB",
                  ]}
                  style={{
                    padding: "2rem",
                    position: "relative",
                    width: "84vw",
                    height: "24rem",
                    background: "White",
                  }}
                  yTitle="Birr Amount"
                  handleChange={handleChange}
                  wgInfoId='wg-location-recieve-trend'
                />
              </ChartWrapper>
            </LongChartWrapper>
            <StandardChartWrapper>
              <CustomBarChartV2
                datas={[facilityCount]}
                labels={[
                  [
                    "Count",
                  ],
                ]}
                fields={[
                  [
                    "NoOfInstitution",
                  ],
                ]}
                styles={[
                  {
                    padding: "1rem",
                    position: "relative",
                    height: "28rem",
                    background: "white",
                  },
                ]}
                chart_options={{
                  title: "Facility Count",
                  showExpandAlt: true,
                  option: "InstitutionType",
                  tooltips: ["InstitutionType"],
                  indexAxis: 'y',
                }}
                changeLoading={facilityCountLoading}
              />
            </StandardChartWrapper>

            <LongChartWrapper>
              <PieChartWrapper title="Distribution by" wgInfoId="wg-location-recieve-trend" handleChange={handleChange} parameter={pieDataParams} setParameter={setPieDataParams} isLoading={pieDataLoading} selectFilterConfig={[YEAR_SELECT_CONFIG]}>
                <CustomPieChart
                  data={!pieDataLoading? pieData: []}
                  label={"Distribution by Program"}
                  fields={[
                    "Amount",
                    "InETB",
                  ]}
                  title="Distribution by Program"
                  style={{
                    position: "relative",
                    height: "23rem",
                    width: "25rem",
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(!pieDataLoading? pieData: [], ["InETB", "Amount"])}
                  columns={parseGridColumns(["InETB", "Amount"])}
                  getRowId={(row) => row.InETB }
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <CustomPieChart
                  data={!pieDataLoading? pieData: []}
                  label={"Distribution by Program"}
                  fields={[
                    "Amount",
                    "InETB",
                  ]}
                  title="Distribution by Program"
                  style={{
                    height:'15rem',
                    width: '32rem',
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
              </PieChartWrapper>
            </LongChartWrapper>
            <StandardChartWrapper>
              <ChartWrapper title="Disposal Amount" wgInfoId="wg-location-disposal-amount" parameter={disposalAmountParams} setParameter={setDisposalAmountParams} isLoading={disposalAmountLoading} 
                datePicker={{
                  date_range: true,
                  start_date_param_name: "From",
                  end_date_param_name: "To",
                  quarterly_report: true,
                }}>
                <CustomPieChart
                  data={disposalAmountParsed}
                  label={"Disposal Amount"}
                  fields={["Amount", "InETB"]}
                  title="Disposal Amount"
                  style={{
                    position: "relative",
                    width: "26rem",
                    height: "24rem",
                    background: "White",
                    padding: "1rem",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(disposalAmountParsed, ["InETB", "Amount"])}
                  columns={parseGridColumns(["InETB", "Amount"])}
                  getRowId={(row) => row.InETB}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <CustomPieChart
                  data={disposalAmountParsed}
                  label={"Disposal Amount"}
                  fields={["Amount", "InETB"]}
                  title="Disposal Amount"
                  style={{
                    height:'15rem',
                    width: '32rem',
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
              </ChartWrapper>
            </StandardChartWrapper>

            {/* <div
              style={{ display: "flex", flexDirection: "row", padding: "1rem" }}
            >
              <div>
                <CustomPieChart
                  data={distByProg}
                  label={"Distribution by Program"}
                  fields={[
                    "Amount",
                    "InETB",
                  ]}
                  filter={{ handleChange, type: "program" }}
                  title="Distribution by Program"
                  style={{
                    position: "relative",
                    minWidth: "20rem",
                    height: "17rem",
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
              </div>
              <div style={{ padding: "1rem" }}></div>
              <div>
                <CustomPieChart
                  data={distByOwnership}
                  label={"Distribution by Ownership Type"}
                  fields={[
                    "Amount",
                    "InETB",
                  ]}
                  filter={{ handleChange, type: "ownershipType" }}
                  title="Distribution by Ownership Type"
                  style={{
                    position: "relative",
                    minWidth: "20rem",
                    height: "17rem",
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
              </div>
              <div style={{ padding: "1rem" }}></div> */}

              {/* <div>
                <CustomPieChart
                  data={distByCommodity}
                  label={"Distribution by Commodity Type"}
                  fields={[
                    "Amount",
                    "InETB",
                  ]}
                  filter={{ handleChange, type: "commodity" }}
                  title="Distribution by Commodity Type"
                  style={{
                    position: "relative",
                    minWidth: "20rem",
                    height: "17rem",
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                padding: "1rem",
              }}
            >
              <div>
                <CustomPieChart
                  data={disposalAmount}
                  label={"Disposal Amount"}
                  fields={[
                    "Amount",
                    "InETB",
                  ]}
                  filter={{ handleChange, type: "disposalAmount" }}
                  title="Disposal Amount"
                  style={{
                    position: "relative",
                    minWidth: "20rem",
                    height: "17rem",
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
              </div>
              <div style={{ padding: "1rem" }}></div>

              <div>
                <CustomPieChart
                  data={distByMode}
                  label={["HPR", "RDF"]}
                  fields={[
                    "Amount",
                    "InETB",
                  ]}
                  filter={{ handleChange, type: "mode" }}
                  title="Distribution by Mode"
                  style={{
                    position: "relative",
                    minWidth: "20rem",
                    height: "17rem",
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
              </div>
            </div> */}

            <TableWrapper>
              <CustomDataGrid
                component_config={{
                  width: "86vw",
                  title: "Recent Issues",
                  data_source_api: RECENT_ISSUES_API,
                  data_property: "Data",
                  columns: recentIssueColumn,
                  isSOH: false,
                  wgInfoId: 'wgLocationsMostRecentIssue',
                  dateColumn: 'TransactionDate',
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    {EnvironmentCode: locationCode},
                    {
                      hasDateRangeFilter: true,
                      startDate: moment().subtract(1, 'weeks').format("YYYY-MM-DD"),
                    }
                  ),
                  date_picker: {
                    date_range: true,
                    start_date_param_name: "From",
                    end_date_param_name: "To",
                    weekly_report: true
                  },
                }}
              />
            </TableWrapper>
            <TableWrapper>
              <CustomDataGrid
                component_config={{
                  width: "86vw",
                  title: "Recent Receives",
                  data_source_api: RECENT_RECEIVES_API,
                  data_property: "Data",
                  columns: recentReceiveColumn,
                  isSOH: false,
                  wgInfoId: 'wgLocationsMostRecentReceipt',
                  dateColumn: 'TransactionDate',
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    {EnvironmentCode: locationCode},
                    {
                      hasDateRangeFilter: true,
                      startDate: moment().subtract(1, 'weeks').format("YYYY-MM-DD"),
                    }
                  ),
                  date_picker: {
                    date_range: true,
                    start_date_param_name: "From",
                    end_date_param_name: "To",
                    weekly_report: true,
                  },
                }}
              />
            </TableWrapper>
            <TableWrapper>
              <CustomDataGrid
                component_config={{
                  width: "86vw",
                  title: "Pending Orders",
                  data_source_api: PENDING_ORDER_API,
                  data_property: "Data",
                  columns: pendingOrderColumn,
                  isSOH: false,
                  wgInfoId: 'wg-location-pendingorders',
                  dateColumn: 'TransactionDate',
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    {
                      EnvironmentCode: locationCode,
                      Month: 1,
                    },
                    {hasDataFilter: false}
                  ),
                }}
              />
            </TableWrapper>
            <TableWrapper>
              <CustomDataGrid
                component_config={{
                  width: "86vw",
                  title: "Stock Status",
                  data_source_api: STOCK_STATUS_LOCATION_API,
                  data_property: "Data",
                  columns: stockStatusColumn,
                  isSOH: false,
                  wgInfoId: 'wgLocationsStockStatus',
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    {
                      OrderBy: "FullItemName",
                      EnvironmentCode: locationCode,
                    },
                    { hasDataFilter: false }
                  ),
                }}
              />
            </TableWrapper>
            <TableWrapper>
              <CustomDataGrid
                component_config={{
                  width: "86vw",
                  title: "Git By Invoice",
                  data_source_api: GIT_BY_INVOICE_API,
                  data_property: "Data",
                  columns: GITByInvoiceColumn,
                  isSOH: false,
                  wgInfoId: 'wgLocationByinvoice',
                  dateColumn: 'ReceiptInvoiceDate',
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    {
                      EnvironmentCode: locationCode,
                    },
                    { hasDateRangeFilter: true }
                  ),
                  date_picker: {
                    date_range: true,
                    start_date_param_name: "From",
                    end_date_param_name: "To",
                  },
                }}
              />
            </TableWrapper>
            <TableWrapper>
              <CustomDataGrid
                component_config={{
                  width: "86vw",
                  title: "Invoices",
                  data_source_api: INVOICE_API,
                  data_property: "Data",
                  columns: invoiceColumn,
                  isSOH: false,
                  wgInfoId: 'wgLocationIssueBydocumenttype',
                  use_default_filter_params: false,
                  defaultFilterParams: loadFilterToDefaultParams(
                    {EnvironmentCode: locationCode,},
                    {
                      hasDateRangeFilter: true,
                      startDate: moment().subtract(3, 'months').format("YYYY-MM-DD"),
                    }
                  ),
                  date_picker: {
                    date_range: true,
                    start_date_param_name: "From",
                    end_date_param_name: "To",
                    quarterly_report: true,
                  },
                }}
              />
            </TableWrapper>
          </GridContainer>
        </div>
      </div>
      {locationPendingModalOpen && (<CustomDetailsModal title={"Pending Orders Details"} open={locationPendingModalOpen} handleClose={setLocationPendingModalOpen} params={{EnvironmentCode: modalLocationPending[0], ModeCode: modalLocationPending[1], RequisitionID: modalLocationPending[2]}} api={PENDING_DETAIL_API} columns={PENDING_DETAIL_COLUMN} />)}
      {locationGitModalOpen && (<CustomDetailsModal title={"GIT By Invoice Details"} open={locationGitModalOpen} handleClose={setLocationGitModalOpen} params={{EnvironmentCode: locationCode, HeaderID: modalLocationGit, RequisitionID: modalLocationPending}} api={GIT_DETAIL_API} columns={GIT_DETAIL_COLUMN} />)}
      {locationInvoiceModalOpen && (<CustomDetailsModal title={"Invoices Details"} open={locationInvoiceModalOpen} handleClose={setLocationInvoiceModalOpen} params={{ReferenceNumber: modalLocationInvoice[0], InstitutionID: modalLocationInvoice[1], DocumentTypeCode: modalLocationInvoice[2], ModeCode: modalLocationInvoice[3],  TransactionDate: modalLocationInvoice[4], EnvironmentCode: modalLocationInvoice[5]}} api={INVOICE_DETAIL_API} columns={INVOICES_DETAIL_COLUMN} />)}
      <Footer />
    </Wrapper>
  );
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1.3rem 1.5rem;
`;

const TableWrapper = styled.div`
    display: flex;
    grid-column: span 3;
`;

const StandardChartWrapper = styled.div`
    display: flex;
    grid-column: span 1;
`;

const LongChartWrapper = styled.div`
    display: flex;
    grid-column: span 2;
`;

const Wrapper = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  .container-box {
    background: #fff;
    width: 100%;
    padding: 25px, 0px;
    margin: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .card-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 0.7rem;
  }
  .flex-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
  }
  .flex-body {
    padding-right: 1rem;
  }
  .container-context {
    display: block;
    padding: 0 1rem 1rem 1rem;
    width: 100%;
    flex: 1;
  }
  .grid {
    margin-bottom: 1rem;
  }
`;
const cardGroup = {
  display: "flex",
  flexDirection: "row",
  paddingBottom: "0.5rem",
  paddingTop: "0.5rem",
  textAlign: "center",
  justifyContent: "start",
};

const cardStyle = {
  width: "7rem",
  height: "3.5rem",
  marginRight: "1rem",

  alignText: "center",
  fontSize: "14px",
};
