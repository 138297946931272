import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import Navbar from "../../shared/NavBar";
import Footer from "../../shared/Footer";
import {
  getProductAvailablity,
  getEnvironemntList,
  getProductAvailablityByContext,
} from "../../../Services/ApiManager";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { StepContext } from "@mui/material";
export default function ProductAvailablity() {
  const [productAvailablity, setProductAvailablity] = useState([]);
  const [envList, setEnvList] = useState([]);
  const [context, setContext] = useState([]);
  const history = useNavigate();

  const productAvailiablityColumn = [
    { field: "ItemSN", headerName: "ID", width: "" },
    { field: "ItemName", headerName: "Item Name", width: "850" },
    { field: "CommodityType", headerName: "Commodity Type", width: "250" },
  ];
  useEffect(() => {
    getEnvironemntList().then((data) => {
      setEnvList(data.data.Data);
    });
    getProductAvailablity("", "").then((data) => {
      setProductAvailablity(data.data.Data);
    });
  }, []);

  const handleEvent = (event) => {
    getProductAvailablityByContext(event.target.value).then((data) => {
      setContext(data.data.Data);
    });
  };
  const handleChange = (event) => {
    setProductAvailablity([]);
    getProductAvailablity(event.target.value).then((data) => {
      setProductAvailablity(data.data.Data);
    });
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={{ color: "#15616d" }} />
        <GridToolbarFilterButton style={{ color: "#15616d" }} />
        {/* <GridToolbarDensitySelector style={{ color: "#15616d" }} /> */}
        <GridToolbarExport style={{ color: "#15616d" }} />
      </GridToolbarContainer>
    );
  }
  return (
    <div>
      <Navbar title={`Fanos Product Availability Dashboard`} />
      <div style={{ padding: "3rem" }}>
        <h5 style={{ margin: "2rem 0 2rem 0" }}>
          Product Availability shows the Available stock at EPSS by Branch
        </h5>
        <select
          style={{
            width: "10rem",
            height: "2rem",
            background: "#F3F3F3",
            marginLeft: "85%",
            borderRadius: "3px",
            marginBottom: "1rem",
          }}
          onChange={(event) => handleChange(event)}
        >
          {envList?.map((el) => (
            <option value={el.EnvironmentCode}>{el.Environment}</option>
          ))}
        </select>
        <DataGrid
          // onRowClick={handleEvent(param, event)}
          styele={{ marginTop: "5rem" }}
          autoHeight
          rows={productAvailablity}
          columns={productAvailiablityColumn}
          getRowId={(row) => row.ItemSN}
          rowHeight={50}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 25]}
          pagination
          components={{ Toolbar: CustomToolbar }}
        />
      </div>
      <Footer />
    </div>
  );
}
