import React, { Component, useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import Footer from "../../shared/Footer";
import { ITEM_DISTRIBUTION_API, LC_CAD_API } from "../../shared/Shared Utils/AppData";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import styled from "styled-components";
import moment from "moment";
import { getEnvironemntList } from "../../../Services/ApiManager";
import { ENVIRONMENT_SELECT_CONFIG } from "../../shared/Shared Utils/DataTableConstants";

function getIndex(params) {
  return `${params.row.autoGeneratedId + 1}`;
}

function getHub(params) {
  return `${params.row.Environment.replace(" Hub", '')}`;
}

function getDate(params) {
  return `${moment(params.row.IssueDate).format("MMMM Do, YYYY")}`;
}

const ITEM_DISTRIBUTION_COLUMN = [
  {
    field: "Index",
    renderHeader: (params) => <strong>{"#"}</strong>,
    width: 50,
    valueGetter: getIndex,
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    width: 400,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    width: 100,
  },
  {
    field: "Hub",
    renderHeader: (params) => <strong>{"Hub"}</strong>,
    width: 100,
    valueGetter: getHub,
  },
  {
    field: "Institution",
    renderHeader: (params) => <strong>{"Facility"}</strong>,
    width: 180,
  },
  {
    field: "OwnershipType",
    renderHeader: (params) => <strong>{"Ownership"}</strong>,
    width: 100,
  },
  {
    field: "IssuedQuantity",
    renderHeader: (params) => <strong>{"Quantity"}</strong>,
    width: 100,
  },
  {
    field: "IssueDate ",
    renderHeader: (params) => <strong>{"Issue Date"}</strong>,
    width: 130,
    valueGetter: getDate,
  },
];


const ItemDistributions = () => {
  function loadFilterToDefaultParams(filterParams, dateFilterFlag) {
    if (dateFilterFlag.hasDateRangeFilter) {
      const start = moment('07/07/2022').subtract(30, 'day').format("YYYY-MM-DD");
      const end = moment('07/07/2022').format("YYYY-MM-DD");
      filterParams["From"] = start;
      filterParams["To"] = end;
    }
    return filterParams;
  }
  return (
    <Wrapper>
      {" "}
      <NavBar title={`Fanos Distribution Dashboard`} />
      <div className="grid-section mt-5">
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <CustomDataGrid
            component_config={{
              width: "90vw",
              title: "Items Distributed to Facilities",
              data_source_api: ITEM_DISTRIBUTION_API,
              data_property: "Data",
              columns: ITEM_DISTRIBUTION_COLUMN,
              isSOH: false,
              wgInfoId: 'wgDistributionReport',
              use_default_filter_params: false,
              select_filter_config: [
                ENVIRONMENT_SELECT_CONFIG,
              ],
              defaultFilterParams: loadFilterToDefaultParams(
                {},
                { hasDateRangeFilter: true }
              ),
              date_picker: {
                date_range: true,
                start_date_param_name: "From",
                end_date_param_name: "To",
                quarter_report: true,
              },
            }}
          />
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
`;

export default ItemDistributions;
