import React, { useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import Footer from "../../shared/Footer";
import styled from "styled-components";

import {
  getDistributionByEquipment, getDistributionByLocation,
} from "../../../Services/chartRequests";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "../Program/style.css";
import sohPic from "../../../assets/cardIcons/soh.svg";
import stackPic from "../../../assets/cardIcons/stack.svg";
import CustomBarChart from "../../shared/Charts/CustomBarChartV2";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import { DISTRIBUTION_PLAN_API } from "../../shared/Shared Utils/AppData";
import ChartWrapper from "../../shared/Charts/ChartWrapper";
import CustomPieChart from "../../shared/Charts/CustomPieChart";
import { DataGrid } from "@mui/x-data-grid";
import CustomCard from "../../shared/Shared Utils/CustomCards";
import { parseGridColumns, parseGridRows } from "../../../Utils/ChartConverter";
import { getPercentageValues } from "../../../Utils/NumberUtils";
import { useMutation, useQuery } from "@tanstack/react-query";
import Dots from "../../../assets/GIFs/dotss.gif";

export default function MedicalEquipmentDistribution() {
  const history = useNavigate();
  const location = useLocation();
  let { ProgramName } = useParams();
  const [distByEquipment, setDistByEquipment] = useState([]);
  const [distByLocation, setDistByLocation] = useState([]);
  const {data: distByQuery = { }, isFetched, isFetching: distByQueryLoading} = useQuery({
    queryKey: ['distByQuery'],
    queryFn: () => getDistributionByEquipment(),
    staleTime: 10800000,
  });
  const [selectedRow, setSelectedRow] = useState(2);
  const [environment, setEnvironment] = useState("HUB");

  const [distribution, setDistribution] = useState("Center to Hub");  

  const [modeCode, setModeCode] = useState("HPR");

  function getIndex(params) {
    return `${params.row.autoGeneratedId + 1}`;
  }

  const DISTRIBUTION_PLAN_COLUMN = [
    {
      field: "NO",
      renderHeader: (params) => <strong>{""}</strong>,
      width: 100,
      valueGetter: getIndex,
    },
    {
      field: "ItemSN",
      renderHeader: (params) => <strong>{"Item SN"}</strong>,
      flex: 1,
    },
    {
      field: "Item",
      renderHeader: (params) => <strong>{"Item"}</strong>,
      flex: 1,
    },
    {
      field: "DistributionPlan",
      renderHeader: (params) => <strong>{"Planned to Issue"}</strong>,
      flex: 1,
    },
    {
      field: "HubsTotalIssuedQuantity",
      renderHeader: (params) => <strong>{"Issued to Facility"}</strong>,
      flex: 1,
    },
    {
      field: "QuantityLeftAtHubs",
      renderHeader: (params) => <strong>{"Hub SOH"}</strong>,
      flex: 1,
    },
    {
      field: "QuantityLeftAtCenter",
      renderHeader: (params) => <strong>{"Center SOH"}</strong>,
      flex: 1,
    },
  ];

  useEffect(() => {
    if(distByEquipment.length > 0){
      setDistByLocation([{location: "Issued to Facility", value: distByEquipment[selectedRow].HubsTotalIssuedQuantity},
          {location: "Quantity Left At Hubs", value: distByEquipment[selectedRow].QuantityLeftAtHubs},
          {location: "Quantity Left At Center", value: distByEquipment[selectedRow].QuantityLeftAtCenter}]);
    }
  }, [selectedRow]);

  useEffect(() => {
    if(isFetched){
      setDistByEquipment(distByQuery.data.Data);
      setDistByLocation([{location: "Issued to Facility", value: distByQuery.data.Data[selectedRow].HubsTotalIssuedQuantity},
        {location: "Quantity Left At Hubs", value: distByQuery.data.Data[selectedRow].QuantityLeftAtHubs},
        {location: "Quantity Left At Center", value: distByQuery.data.Data[selectedRow].QuantityLeftAtCenter}]);
    }
  }, [isFetched]);

  return (
  <Wrapper>
    <div>
      {" "}
      <NavBar title={`Fanos Medical Equipment Distribution Dashboard`} />
    </div>
    <div className="container-context mt-5">
      <div>
        <div className="container-context">
          {" "}
          <div>
            <div className="grid-section">
              <div className="grid" style={{padding: '0 5vw 1rem'}}>
                <CustomBarChart
                  datas={[getPercentageValues( ["QuantityLeftAtCenter", "QuantityLeftAtHubs", "HubsTotalIssuedQuantity"], distByEquipment)]}
                  labels={[['Quantity left at Center', 'Quantity left at Hub', 'Issued to Facility']]}
                  fields={[
                    ["QuantityLeftAtCenterPercentage", "QuantityLeftAtHubsPercentage", "HubsTotalIssuedQuantityPercentage"]
                  ]}
                  styles={[
                    {
                      padding: "1rem",
                      position: "relative",
                      width: "80vw",
                      height: "25.8rem",
                      background: "white",
                    },
                  ]}
                  chart_options={{
                    title: "Distribution by Equipment",
                    showExpandAlt: true,
                    option: "Item",
                    indexAxis: "y",
                    yTitle: "Quantity",
                    max: 100,
                    tooltips: ["Item"],
                    wgInfoId: "wg-distribution-by-equipment",
                  }}
                  chartTypes={["bar-chart"]}
                />
              </div>
              <div className="grid" style={{padding: '0 5vw 1rem'}}>
                <CustomDataGrid
                  component_config={{
                    width: "90vw",
                    title: "Distribution Plan",
                    data_source_api: DISTRIBUTION_PLAN_API,
                    data_property: "Data",
                    columns: DISTRIBUTION_PLAN_COLUMN,
                    isSOH: false,
                    wgInfoId: 'wgDistributionPlanListMede',
                    use_default_filter_params: false,
                  }}
                />
              </div>
              <div className="grid" style={{padding: '0 5vw 1rem',}}>
                <h2 className="grid-title">Item Distribution
                  {distByQueryLoading && (<span>
                    <img src={Dots} alt="" />
                  </span>)}
                </h2>
                <div className="flex-box-container">
                  <div className="card-container" style={{display: "flex", flexDirection:"column", width: "60%", border: "1px solid lightgrey", padding: "10px 10px"}}>
                    <h6 className="grid-title" style={{color: "grey"}}>Item: {distByEquipment.length > 0? distByEquipment[selectedRow].Item: ""}</h6>
                    <div className="tool-bar-item" style={{flexGrow: '0', width: '20rem'}}>
                      <select
                          className="select"
                          style={{marginLeft: 0, marginBottom: 0, width: "19rem"}}
                          onChange={(e) => {setSelectedRow(e.target.value)}}
                        >
                          {distByEquipment.map((el, i) => (
                          <option key={el.Item} value={i} selected={i===selectedRow}>
                              {el.Item}
                          </option>
                          ))}
                      </select>
                    </div>
                    <div style={{display: "flex",padding: "10px 10px", alignItems: "center"}}>
                      <div className="flex-body">
                        <div className="card-container" style={{display: "flex", flexDirection:"column", border: "1px solid lightgrey", padding: "10px 10px", alignItems: "center"}}>
                          <CustomCard
                            widget_config={{
                              title: "Distribution Plan",
                              value: distByEquipment[selectedRow]?.DistributionPlan || 0,
                              logo_url: sohPic,
                            }}
                          />
                          <CustomCard
                            widget_config={{
                              title: "Issued to Facilities",
                              value: distByEquipment[selectedRow]?.HubsTotalIssuedQuantity || 0,
                              logo_url: stackPic,
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex-body">
                        <ChartWrapper 
                          title="Distribution by Location" 
                          wgInfoId="wgMedeDistributionByLocation"
                          showYearFilter={false}
                        >
                          <CustomPieChart
                            data={distByLocation}
                            label={["Location"]}
                            fields={["value", "location",]}
                            style={{
                              position: "relative",
                              width: "25rem",
                              height: "22rem",
                              background: "White",
                              padding: "0rem 1rem 1rem 1rem",
                            }}
                          />
                          <DataGrid
                            style={{
                              padding: "1rem",
                            }}
                            initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                            autoHeight
                            rows={parseGridRows(distByLocation, [ "location", "value"])}
                            columns={parseGridColumns(["location", "value"])}
                            getRowId={(row) => row.location}
                            rowHeight={30}
                            pageSize={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            pagination
                            animateRows={true}
                            disableColumnSelector
                            components={{}}
                          />
                          <CustomPieChart
                            data={distByLocation}
                            label={"Location"}
                            fields={["value", "location"]}
                            style={{
                              height:'15rem',
                              width: '28rem',
                              padding: "0rem 1rem 1rem 1rem",
                            }}
                          />
                        </ChartWrapper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </Wrapper>)
}

const Wrapper = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  .container-box {
    background: #fff;
    width: 100%;
    padding: 25px, 0px;
    margin: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .card-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 0.7rem;
  }
  .container-context {
    display: flex;
    flex-direction: row;
    padding: 0 1rem 1rem 1rem;
    width: 100%;
    flex: 1;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    padding-left: 3rem;
    flex-wrap: wrap;
    align-items: center;
  }
  .button {
    border: 1px solid transparent;
    height: 2rem;
    width: 6rem;
    font-size: 14px;
    background-color: cadetblue;
    color: white;
    margin-right: 1rem;
    border-radius: 4px;
  }
  .flex-row {
    display: flex;
    width: 100%;
    flex-grow: 1;
  }

  .flex-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
  }
  .flex-body {
    padding-right: 1rem;
  }
  .grid {
    margin-bottom: 1rem;
  }
  .group-chart {
    background-color: white;
    position: relative;
    margin-bottom: 0.1rem;
    border: 1px solid lightgrey;
    padding: 10px 5px 10px 5px;
  }
`;