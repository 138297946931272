import React, { useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import Footer from "../../shared/Footer";
import styled from "styled-components";

import {
  getEnvironemntList,
  getHeaderInformation, getProgramGroup, search, searchByEnvironment,
} from "../../../Services/ApiManager";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Button, Container, Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import Dots from "../../../assets/GIFs/dotss.gif";
import { FaSearch } from "react-icons/fa";

export default function ItemSummary() {
  const history = useNavigate();
  const [shareBySite, setShareBySite] = useState([]);
  const [locationDropdown, setLocationDropdown] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchData, setSearchData] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedSite, setSelectedSite] = useState({
    Environment: "National",
    EnvironmentCode: "National",
    EnvironmentGroup: "PFSA",
  });


  useState(shareBySite);
  const pageFilterParams = {};

  useEffect(() => {
    function fetchData() {
      const user = localStorage.getItem("userInfo");
      if (!user) history("/login");
      const userId = JSON.parse(user)?.GroupID;

      getEnvironemntList().then((data) => {
        data.data.Data = [
          {
            Environment: "National",
            EnvironmentCode: "National",
            EnvironmentGroup: "PFSA",
          },
          ...data.data.Data,
        ];
        setLocationDropdown(data.data.Data);
      });

      setSearchLoading(true);
      search(searchTerm).then((data) => {
          setSearchData(data.data.Data.filter((d) => {
          return true}));
          setSearchLoading(false);
        });
    }
    fetchData();
  }, []);


  const onSearchSubmit = () => {
    if (searchTerm) {
      setSearchLoading(true);
      if (selectedSite.Environment != "National") {
        searchByEnvironment(searchTerm, selectedSite.EnvironmentCode).then(
          (data) => {
            setSearchData(data.data.Data.filter((d) => d.ViewPage.split('#')[3] === d.FilterValue.split('&')[0].split('=')[1] && d.FilterValue.split('=')[0] === 'ProductSN'));
            setSearchLoading(false);
          }
        );
      } else {
        search(searchTerm).then((data) => {
          setSearchData(data.data.Data.filter((d) => d.ViewPage.split('#')[3] === d.FilterValue.split('&')[0].split('=')[1] && d.FilterValue.split('=')[0] === 'ProductSN'));
          setSearchLoading(false);
        });
      }
    }
  };

  const onSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelect = (site) => {
    setSelectedSite(site);
  };

  return (
  <Wrapper>
      <NavBar title='Fanos Item Summary Dashboard' />
      <div className="col-md-12">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            backgroundColor: "transparent",
            margin: "0 6.5rem",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "row" }}
            class="d-flex justify-content-center"
          >
            <InputGroup className="mt-5">
              <div 
                style={{backgroundColor: "#eeeeee"}}>
              <DropdownButton
                variant="secondary"
                title={selectedSite.Environment}
                id="input-group-locations "
              >
                {locationDropdown.map((location) => (
                  <Dropdown.Item
                    onClick={() => {
                      handleSelect(location);
                    }}
                    href="#"
                  >
                    {location.Environment}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              </div>
              <Form.Control
                style={{width: "25rem"}}
                aria-label="Text input with dropdown button"
                placeholder="Search for items, programs, manufacturers"
                value={searchTerm}
                onChange={onSearchTermChange}
              />
              <Button
                style={{backgroundColor: "#e0e0e0", border: 0}}
                variant="outline-secondary"
                id="button-addon2"
                onClick={onSearchSubmit}
              >
                <FaSearch />
              </Button>
            </InputGroup>
          </div>
        </div>
        {searchLoading && <img src={Dots} alt="loading..." className="dots" />}
        {searchData && !searchLoading && (
          <div>
            <Container style={{border: 'none'}} className="card-style">
              <div class="row SearchResult" style={{ marginBottom: "20px" }}>
                <div class="col-md-2"></div>
                    <div class="col-md-8">
                    {searchData?.length == 0 && (
                        <p
                          style={{
                            marginTop: "9px",
                            paddingRight: "30px",
                            textAlign: "center",
                            fontSize: "11px",
                            color: "gray",
                          }}
                        >
                        No result matched
                        </p>
                    )}

                    {searchData?.length > 0 && (
                        <table
                        class="table"
                        style={{ marginLeft: "50px", width: "90%" }}
                        >
                        {searchData.map((element) => (
                            <tr>
                            <td style={{ paddingBottom: 0, marginBottom: 0 }}>
                                <a style={{ cursor: "pointer"}} onClick={() => {
                                  history('/Items', {
                                    state: {
                                      ProductSN: element.FilterValue.split('&')[0].split("=")[1],
                                      ProgramCode: null,
                                      ProductName: element.SearchResultString,
                                    },});
                                }}>
                                  {element.SearchResultString}
                                </a>
                                <br />
                                <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                                >
                                <em>
                                    <span style={{ fontSize: "11px" }}>
                                    {element.SearchResultParentString}
                                    </span>
                                </em>
                                <em>
                                    <span style={{ fontSize: "11px" }}>
                                    {element.ElementType}
                                    </span>
                                </em>
                                </div>
                            </td>
                            </tr>
                        ))}
                        </table>
                    )}
                    </div>
                </div>
                </Container>
            </div>
            )}
      </div>
      <Footer />
    </Wrapper>)
}

const Wrapper = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  .container-box {
    background: #fff;
    width: 100%;
    padding: 25px, 0px;
    margin: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .card-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 0.7rem;
  }
  .container-context {
    display: flex;
    flex-direction: row;
    padding: 0 1rem 1rem 1rem;
    width: 100%;
    flex: 1;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    padding-left: 3rem;
    flex-wrap: wrap;
    align-items: center;
  }
  .button {
    border: 1px solid transparent;
    height: 2rem;
    width: 6rem;
    font-size: 14px;
    background-color: cadetblue;
    color: white;
    margin-right: 1rem;
    border-radius: 4px;
  }
  .flex-row {
    display: flex;
    width: 100%;
    flex-grow: 1;
  }

  .flex-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
  }
  .flex-body {
    padding-right: 1rem;
  }
  .grid {
    margin-bottom: 1rem;
  }
  .group-chart {
    background-color: white;
    position: relative;
    margin-bottom: 0.1rem;
    border: 1px solid lightgrey;
    padding: 10px 5px 10px 5px;
  }
`;