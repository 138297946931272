import React, { } from "react";
import NavBar from "../../shared/NavBar";
import Footer from "../../shared/Footer";
import { LC_CAD_API } from "../../shared/Shared Utils/AppData";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import styled from "styled-components";
import { RiErrorWarningFill } from "react-icons/ri";
import { FaFlag, FaInfo } from "react-icons/fa";
import { getShortDate } from "../../../Utils/NumberUtils";
import { Tooltip } from "@mui/material";


const getLateShipment = (row) => {
  const shipmentDate = row.LatestestShipmentExtensionDate !== null 
    ? row.LatestestShipmentExtensionDate 
    : row.LatestShipmentDate;
  return shipmentDate == null ? false : new Date().getTime() > new Date(shipmentDate).getTime();
}

const getExpiredShipment = (row) => {
  const expiryDate = row.LCCADExtensionDate !== null 
    ? row.LCCADExtensionDate 
    : row.LCCADExpiryDate;
  return expiryDate == null ? false : new Date().getTime() > new Date(expiryDate).getTime();
}

const LC_CAD_COLUMN = [
  {
    field: "PurchaseOrderNumber",
    renderHeader: (params) => <strong>{"PO No"}</strong>,
    width: 100,
  },
  {
    field: "Supplier",
    renderHeader: (params) => <strong>{"Supplier"}</strong>,
    flex: 1,
  },
  {
    field: "SupplierCountry",
    renderHeader: (params) => <strong>{"Country"}</strong>,
    flex: 1,
  },
  {
    field: "Currency",
    renderHeader: (params) => <strong>{"Currency"}</strong>,
    flex: 1,
  },
  {
    field: "LCCADExpiryDate",
    renderHeader: (params) => <strong>{"Expiry Date"}</strong>,
    flex: 1,
    renderCell: (params) => (<div>{params.row.LCCADExtensionDate !== null? getShortDate({ value: params.row.LCCADExtensionDate}): getShortDate({ value: params.row.LCCADExpiryDate})} {getExpiredShipment(params.row) && <Tooltip title={"Expired LC/CAD"}><RiErrorWarningFill style={{color: "red" }} /></Tooltip>}</div>),
  },
  {
    field: "LatestShipmentDate",
    renderHeader: (params) => <strong>{"Latest Shipment Date"}</strong>,
    flex: 1,
    renderCell: (params) => (<div>{params.row.LatestestShipmentExtensionDate !== null? getShortDate({ value: params.row.LatestestShipmentExtensionDate}): getShortDate({ value: params.row.LatestShipmentDate})} {getLateShipment(params.row)? <Tooltip title={"Late LC/CAD"}><FaFlag style={{color: "rgb(207, 181, 33)" }} /></Tooltip>: params.row.LatestestShipmentExtensionDate  && params.row.LCCADExtensionCount > 0? <Tooltip title={`extended for ${params.row.LCCADExtensionCount} days`}><FaInfo style={{color: "rgb(38, 38, 183)" }} /></Tooltip>: <></>}</div>),
  },
  {
    field: "DaysTillExpiry",
    renderHeader: (params) => <strong>{"Days till Expiry"}</strong>,
    flex: 1,
    valueFormatter: (params) => params.value >= 0? params.value: 'Expired',
  },
  {
    field: "ShipmentOfficer",
    renderHeader: (params) => <strong>{"Shipment Officer"}</strong>,
    flex: 1,
  },
];


const CADExpiry = () => {
  return (
    <Wrapper>
      {" "}
      <NavBar title={`Fanos LC CAD EXPIRY Dashboard`} />
      <div className="grid-section mt-5">
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <CustomDataGrid
            component_config={{
              width: "90vw",
              title: "LC CAD Expiry",
              data_source_api: LC_CAD_API,
              data_property: "Data",
              columns: LC_CAD_COLUMN,
              isSOH: false,
              wgInfoId: 'wgLcCadExpriyReport',
              use_default_filter_params: false,
              defaultFilterParams: {},
            }}
          />
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
`;

export default CADExpiry;
