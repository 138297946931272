import React, { useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import Footer from "../../shared/Footer";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import { ERP_Receive_API } from "../../shared/Shared Utils/AppData";
import styled from "styled-components";
import { getGShortDate } from "../../../Utils/NumberUtils";
import DynamicDataGrid from "../../../Packages/DynamicWidget/Widgets/DynamicDataGrid";

const RECEIVE_REPORT_COLUMN = [
  {
    field: "MaterialNumber",
    renderHeader: (params) => <strong>{"Material No"}</strong>,
    width: 150,
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    width: 300
  },
  {
    field: "CommodityType",
    renderHeader: (params) => <strong>{"Type"}</strong>,
    width: 150
  },
  {
    field: "BatchNumber",
    renderHeader: (params) => <strong>{"Batch No"}</strong>,
    width: 100
  },
  {
    field: "PDSUnit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    width: 100
  },
  {
    field: "TransactionDate",
    renderHeader: (params) => <strong>{"Transaction Date"}</strong>,
    width: 120,
    valueFormatter: getGShortDate,
  },
  {
    field: "AmountInLocalCurrency",
    renderHeader: (params) => <strong>{"Amount"}</strong>,
    width: 120,
    type: 'number'
  },
  {
    field: "Quantity",
    renderHeader: (params) => <strong>{"Quantity"}</strong>,
    width: 100,
    type: 'number'
  },
  {
    field: "Plant",
    renderHeader: (params) => <strong>{"Plant code"}</strong>,
    width: 100
  },
  {
    field: "PlantName",
    renderHeader: (params) => <strong>{"Plant"}</strong>,
    width: 200
  }
];

const ReceiveReport = () => {
  return (
    <Wrapper>
      {" "}
      <NavBar title={`ERP Transaction Report`} />
      <div className="grid-section mt-5">
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <CustomDataGrid
            component_config={{
              width: "94vw",
              title: "Receive Report",
              data_source_api: ERP_Receive_API,
              data_property: "Data",
              columns: RECEIVE_REPORT_COLUMN,
              wgInfoId: 'wgReceiveReport',
              use_default_filter_params: false,
              select_filter_config: [],
              defaultFilterParams: {}
            }}
          />
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
`;

export default ReceiveReport;
