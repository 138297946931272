import React, { Component, useEffect } from "react";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import { Divider } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormatDecimal } from "../../Utils/NumberUtils";

export default function SideNav2({ data, header }) {
  const history = useNavigate();
  return (
    <Wrapper>
      <div className="inner-wrapper">
        <SidebarMenu className="sidebar-menu flex-row flex-wrap">
          {data &&
            data.map((element) => (
            <OverlayTrigger className="grow-1" placement="right" overlay={(
              <Tooltip id="tooltip">
                {element.ProductName}[{element.Unit}]
              </Tooltip>
            )}>
              <SidebarMenu.Header
                className="sideNav-header grow-1"
                onDoubleClick={() => {
                  history("/Items", {
                    state: element,
                  });
                }}
                style={{
                  color: element.SS === 'Normal'? '#265c00': element.SS === 'Excess'? '#0b7075': element.SS === 'Below Min'? '#ddc908': element.SS === 'Stocked Out'? '#c94057': element.SS === 'Below EOP'? '#e06c00':'black',}}
              >
                <SidebarMenu.Nav.Link className="sideNav-link"
                style={{
                  color: element.SS === 'Normal'? '#265c00': element.SS === 'Excess'? '#0b7075': element.SS === 'Below Min'? '#ddc908': element.SS === 'Stocked Out'? '#c94057': element.SS === 'Below EOP'? '#e06c00':'black',}}>
                  {element && element.ProductCN.length > 12
                    ? typeof element.ProductCN !== "string"
                      ? element.ProductCN.subString(0, 10)
                      : element.ProductCN
                    : element.ProductCN}
                </SidebarMenu.Nav.Link>
                <br />{" "}
                <SidebarMenu.Brand className="sideNav-body">
                  {FormatDecimal(element.SOH, 0)}
                </SidebarMenu.Brand>
                <br />
              </SidebarMenu.Header>
            </OverlayTrigger>
            ))}
        </SidebarMenu>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  .inner-wrapper {
    background: white;
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin-right: 1rem;
    min-width: 7rem;
  }
  .sideNav-header {
    margin: 0.5rem;
    border: 1px solid grey;
    border-radius: 0px 22px 19px 22px;
    min-width: 6rem;
  }
  .sideNav-header:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  .sideNav-link {
    text-decoration: none;
    color: grey;
    font-size: 14px;
    max-width: 7rem;
  }
  .sideNav-body {
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
  }
  .sidebar-menu {
    text-align: center;
    padding: 1rem;
  }

  @media screen and (min-width: 750px) {
    .inner-wrapper {
      display: none;
    }
  }
`;
