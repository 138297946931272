import React, { useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import Footer from "../../shared/Footer";
import styled from "styled-components";

import {
  getAdverseByAgeGroup,
  getAdverseByGender,
  getAdverseByOccurrence,
  getAdverseByVaccineType,
  getBeneficiariesByAgeGroup,
  getBeneficiariesByGender,
  getCommonAdverseEvents,
} from "../../../Services/ApiManager";

import CustomPieChart from "../../shared/Charts/CustomPieChart";
import "react-datepicker/dist/react-datepicker.css";
import "../Program/style.css";
import {
  PAGINATION_DEFAULT_PARAM,
  VACCINE_TYPE_SELECT_CONFIG,
} from "../../shared/Shared Utils/DataTableConstants";
import { parseGridColumns, parseGridRows, } from "../../../Utils/ChartConverter";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ChartWrapper from "../../shared/Charts/ChartWrapper";
import CustomBarChart from "../../shared/Charts/CustomBarChartV2";
import { BENEFICIARY_API, BENEFICIARY_BY_AGE_API } from "../../shared/Shared Utils/AppData";
import { BENEFICIARY_BY_AGE_COLUMN, BENEFICIARY_COLUMN } from "./AEFITableColumn";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import { useQuery } from "@tanstack/react-query";
import { FormatDecimal } from "../../../Utils/NumberUtils";

export default function AEFI() {
  const [benefByGenderParams, setBenefByGenderParams] = useState({});
  const {data: { data: { Data:  benefByGender }} = { data: { Data: [] }}, isFetching: benefByGenderLoading} = useQuery({
    queryKey: ['benefByGenderRate', benefByGenderParams],
    queryFn: () => getBeneficiariesByGender(benefByGenderParams),
    staleTime: 10800000,
  });
  const [benefByAgeParams, setBenefByAgeParams] = useState({});
  const {data: { data: { Data:  benefByAge }} = { data: { Data: [] }}, isFetching: benefByAgeLoading} = useQuery({
    queryKey: ['benefByAge', benefByAgeParams],
    queryFn: () => getBeneficiariesByAgeGroup(benefByAgeParams),
    staleTime: 10800000,
  });
  const [adverseByGenderParams, setAdverseByGenderParams] = useState({});
  const {data: { data: { Data:  adverseByGender }} = { data: { Data: [] }}, isFetching: adverseByGenderLoading} = useQuery({
    queryKey: ['adverseByGenderRate', adverseByGenderParams],
    queryFn: () => getAdverseByGender(adverseByGenderParams),
    staleTime: 10800000,
  });
  const [adverseByAgeParams, setAdverseByAgeParams] = useState({});
  const {data: { data: { Data:  adverseByAge }} = { data: { Data: [] }}, isFetching: adverseByAgeLoading} = useQuery({
    queryKey: ['adverseByAge', adverseByAgeParams],
    queryFn: () => getAdverseByAgeGroup(adverseByAgeParams),
    staleTime: 10800000,
  });
  const [adverseByVaccineParams, setAdverseByVaccineParams] = useState({});
  const {data: { data: { Data:  adverseByVaccine }} = { data: { Data: [] }}, isFetching: adverseByVaccineLoading} = useQuery({
    queryKey: ['adverseByVaccine', adverseByVaccineParams],
    queryFn: () => getAdverseByVaccineType(adverseByVaccineParams),
    staleTime: 10800000,
  });
  const [adverseByOccurrenceParams, setAdverseByOccurrenceParams] = useState({});
  const {data: { data: { Data:  adverseByOccurrence }} = { data: { Data: [] }}, isFetching: adverseByOccurrenceLoading} = useQuery({
    queryKey: ['adverseByOccurrence', adverseByOccurrenceParams],
    queryFn: () => getAdverseByOccurrence(adverseByOccurrenceParams),
    staleTime: 10800000,
  });
  const [commonAdverseParams, setCommonAdverseParams] = useState(PAGINATION_DEFAULT_PARAM);
  const {data: { data: { Data:  commonAdverse }} = { data: { Data: [] }}, isFetching: commonAdverseLoading} = useQuery({
    queryKey: ['commonAdverse', commonAdverseParams],
    queryFn: () => getCommonAdverseEvents(commonAdverseParams),
    staleTime: 10800000,
  });


  return (
  <Wrapper>
    <div>
      {" "}
      <NavBar title={`Fanos Adverse Event Following Immunization Dashboard`} />
    </div>
    <div className="container-context mt-5">
      <div>
        <GridContainer>
          <StandardChartWrapper>
            <ChartWrapper
                title="Beneficiaries Under Active Surveillance by Gender" 
                wgInfoId="wgAefiBeneficiariesByGender"
                selectFilterConfig={[VACCINE_TYPE_SELECT_CONFIG,]}
                defaultFilterParams={{}}
                isLoading={benefByGenderLoading}
                parameter={benefByGenderParams}
                setParameter={setBenefByGenderParams}
              >
                <CustomPieChart
                  data={benefByGender}
                  label={"Gender"}
                  fields={["Count", "Gender"]}
                  style={{
                    position: "relative",
                    width: "25rem",
                    height: "22rem",
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(benefByGender, ["Gender", "Count"])}
                  columns={parseGridColumns(["Gender", "Count"])}
                  getRowId={(row) => row.Gender}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <CustomPieChart
                  data={benefByGender}
                  label={"Gender"}
                  fields={["Count", "Gender"]}
                  style={{
                    height:'15rem',
                    width: '28rem',
                    padding: "0rem 1rem 1rem 1rem",
                    }}
                  />
            </ChartWrapper>
          </StandardChartWrapper>
          <LongChartWrapper>
                <CustomBarChart
                  datas={[benefByAge.filter((b) => b.AgeGroup!== null)]}
                  labels={[["Amount"]]}
                  fields={[
                    [
                      "Count"
                    ],
                  ]}
                  styles={[
                    {
                      padding: "1rem",
                      position: "relative",
                      width: "54vw",
                      height: "25.8rem",
                      background: "white",
                    },
                  ]}
                  selectFilterConfig={[VACCINE_TYPE_SELECT_CONFIG,]}
                  parameter={benefByAgeParams}
                  setParameter={setBenefByAgeParams}
                  defaultFilterParams={{}}
                  chart_options={{
                    title: "Beneficiaries Under Active Surveillance by Age Group",
                    showExpandAlt: true,
                    yTitle: "Beneficiaries",
                    hideLegend: true,
                    option: "AgeGroup",
                    tooltips: [
                    ],
                    wgInfoId: "wgAefiBeneficiariesByAgeGroup",
                  }}
                  chartTypes={["bar-chart"]}
                  changeLoading={benefByAgeLoading}
                />
              </LongChartWrapper>

            
            <StandardChartWrapper>
                <ChartWrapper
                  title="Reported Adverse Events by Gender" 
                  wgInfoId="wgAefiAdverseEventsByGender"
                  selectFilterConfig={[VACCINE_TYPE_SELECT_CONFIG,]}
                  defaultFilterParams={{}}
                  isLoading={adverseByGenderLoading}
                  parameter={adverseByGenderParams}
                  setParameter={setAdverseByGenderParams}
                >
                  <CustomPieChart
                    data={adverseByGender}
                    label={"Gender"}
                    fields={["Count", "Gender"]}
                    style={{
                      position: "relative",
                      width: "25rem",
                      height: "22rem",
                      background: "White",
                      padding: "0rem 1rem 1rem 1rem",
                    }}
                  />
                  <DataGrid
                    style={{
                      padding: "1rem",
                    }}
                    initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                    autoHeight
                    rows={parseGridRows(adverseByGender, [ "Gender", "Count"])}
                    columns={parseGridColumns(["Gender", "Count"])}
                    getRowId={(row) => row.Gender}
                    rowHeight={30}
                    pageSize={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    pagination
                    animateRows={true}
                    disableColumnSelector
                    components={{ Toolbar: GridToolbar }}
                  />
                  <CustomPieChart
                    data={adverseByGender}
                    label={"Gender"}
                    fields={["Count", "Gender"]}
                    style={{
                      height:'15rem',
                      width: '28rem',
                      padding: "0rem 1rem 1rem 1rem",
                    }}
                  />
                </ChartWrapper>
              </StandardChartWrapper>
              <LongChartWrapper>
                <CustomBarChart
                  datas={[adverseByAge]}
                  labels={[["Amount"]]}
                  fields={[
                    [
                      "Count"
                    ],
                  ]}
                  styles={[
                    {
                      padding: "1rem",
                      position: "relative",
                      width: "54vw",
                      height: "25.8rem",
                      background: "white",
                    },
                  ]}
                  selectFilterConfig={[VACCINE_TYPE_SELECT_CONFIG,]}
                  parameter={adverseByAgeParams}
                  setParameter={setAdverseByAgeParams}
                  defaultFilterParams={{}}
                  chart_options={{
                    title: "Reported Adverse Events by Age Group",
                    showExpandAlt: true,
                    option: "AgeGroup",
                    tooltips: [
                    ],
                    wgInfoId: "wgAefiAdverseEventsByAgeGroup",
                  }}
                  chartTypes={["bar-chart"]}
                  changeLoading={adverseByAgeLoading}
                />
              </LongChartWrapper>

            <LongChartWrapper>
              <CustomBarChart
                datas={[adverseByVaccine]}
                labels={[["Amount"]]}
                fields={[
                  [
                    "Count"
                  ],
                ]}
                styles={[
                  {
                    padding: "1rem",
                    position: "relative",
                    width: "54vw",
                    height: "25.8rem",
                    background: "white",
                  },
                ]}
                defaultFilterParams={{}}
                chart_options={{
                  title: "Reported Adverse Events by Vaccine Type",
                  showExpandAlt: true,
                  option: "Vaccine",
                  yTitle: "Adverse Events",
                  hideLegend: true,
                  tooltips: [
                  ],
                  wgInfoId: "wgAefiAdverseEventsByVaccineType",
                }}
                chartTypes={["bar-chart"]}
                changeLoading={adverseByVaccineLoading}
              />
              </LongChartWrapper>
              <StandardChartWrapper>
                <ChartWrapper 
                  title="Reported Adverse Events by Occurrence Period" 
                  wgInfoId="wgAefiAdverseEventsByOccurrencePeriod"
                  selectFilterConfig={[VACCINE_TYPE_SELECT_CONFIG,]}
                  defaultFilterParams={{}}
                  isLoading={adverseByOccurrenceLoading}
                  parameter={adverseByOccurrenceParams}
                  setParameter={setAdverseByOccurrenceParams}
                >
                  <CustomPieChart
                    data={adverseByOccurrence}
                    label={"Occurrence Period"}
                    fields={["Count", "OccurrencePeriod"]}
                    style={{
                      position: "relative",
                      width: "25rem",
                      height: "22rem",
                      background: "White",
                      padding: "0rem 1rem 1rem 1rem",
                    }}
                  />
                  <DataGrid
                    style={{
                      padding: "1rem",
                    }}
                    initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                    autoHeight
                    rows={parseGridRows(adverseByOccurrence, [ "OccurrencePeriod", "Count"])}
                    columns={parseGridColumns(["OccurrencePeriod", "Count"])}
                    getRowId={(row) => row.OccurrencePeriod}
                    rowHeight={30}
                    pageSize={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    pagination
                    animateRows={true}
                    disableColumnSelector
                    components={{ Toolbar: GridToolbar }}
                  />
                  <CustomPieChart
                    data={adverseByOccurrence}
                    label={"Occurrence Period"}
                    fields={["Count", "OccurrencePeriod"]}
                    style={{
                      height:'15rem',
                      width: '28rem',
                      padding: "0rem 1rem 1rem 1rem",
                    }}
                  />
                </ChartWrapper>
              </StandardChartWrapper>

            <TableWrapper>
              <CustomBarChart
                datas={[commonAdverse.map((d) => {
                  return{
                    Symptom: d.Symptom,
                    Percentage: FormatDecimal(d.Percentage, 1),
                  }}
                  )]}
                labels={[["Amount"]]}
                fields={[
                  [
                    "Percentage"
                  ],
                ]}
                styles={[
                  {
                    padding: "1rem",
                    position: "relative",
                    height: "22.8rem",
                    background: "white",
                  },
                ]}
                defaultFilterParams={PAGINATION_DEFAULT_PARAM}
                selectFilterConfig={[VACCINE_TYPE_SELECT_CONFIG,]}
                parameter={commonAdverseParams}
                setParameter={setCommonAdverseParams}
                chart_options={{
                  title: "Most Common Adverse Events",
                  showExpandAlt: true,
                  option: "Symptom",
                  indexAxis: "y",
                  yTitle: "Amount",
                  max: 25,
                  tooltips: ["Symptom"],
                  wgInfoId: "wgAefiMostCommonAdverseEvents",
                }}
                chartTypes={["bar-chart"]}
                changeLoading={commonAdverseLoading}
              />
            </TableWrapper>            

            <TableWrapper>
                <CustomDataGrid
                  component_config={{
                    title: "Reported Beneficiaries by Age",
                    data_source_api: BENEFICIARY_BY_AGE_API,
                    data_property: "Data",
                    columns: BENEFICIARY_BY_AGE_COLUMN,
                    isSOH: false,
                    wgInfoId: 'wgAefiBeneficiariesByAge',
                    use_default_filter_params: false,
                    select_filter_config: [
                      VACCINE_TYPE_SELECT_CONFIG,
                    ],
                    defaultFilterParams: {},
                  }}
                />
              </TableWrapper>
              <TableWrapper>
                <CustomDataGrid
                  component_config={{
                    title: "Reported Beneficiaries",
                    data_source_api: BENEFICIARY_API,
                    data_property: "Data",
                    columns: BENEFICIARY_COLUMN,
                    isSOH: false,
                    wgInfoId: 'wgAefiBeneficiariesList',
                    use_default_filter_params: false,
                    select_filter_config: [
                      VACCINE_TYPE_SELECT_CONFIG,
                    ],
                    defaultFilterParams: {},
                  }}
                />
              </TableWrapper>
            </GridContainer>
      </div>
    </div>
    <Footer />
  </Wrapper>)
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1.3rem 1.5rem;
`;

const TableWrapper = styled.div`
    display: flex;
    grid-column: span 3;
`;

const StandardChartWrapper = styled.div`
    display: flex;
    grid-column: span 1;
`;

const LongChartWrapper = styled.div`
    display: flex;
    grid-column: span 2;
`;

const Wrapper = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  .container-box {
    background: #fff;
    width: 100%;
    padding: 25px, 0px;
    margin: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .card-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 0.7rem;
  }
  .container-context {
    display: flex;
    flex-direction: row;
    padding: 0 1rem 1rem 1rem;
    width: 100%;
    flex: 1;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    padding-left: 3rem;
    flex-wrap: wrap;
    align-items: center;
  }
  .button {
    border: 1px solid transparent;
    height: 2rem;
    width: 6rem;
    font-size: 14px;
    background-color: cadetblue;
    color: white;
    margin-right: 1rem;
    border-radius: 4px;
  }
  .flex-row {
    display: flex;
    width: 100%;
    flex-grow: 1;
  }

  .flex-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
  }
  .flex-body {
    padding-right: 1rem;
  }
  .grid {
    margin-bottom: 1rem;
  }
  .group-chart {
    background-color: white;
    position: relative;
    margin-bottom: 0.1rem;
    border: 1px solid lightgrey;
    padding: 10px 5px 10px 5px;
  }
`;