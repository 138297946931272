import { Box, Button, Tooltip, styled, tooltipClasses } from "@mui/material";
import clsx from "clsx";
import { FaExpandAlt } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import { useFanosStore } from "../../shared/Shared Utils/store";
import { FormatDecimal, getAllShare, getGShortDate, getShare } from "../../../Utils/NumberUtils";
import moment from "moment";
import { ProgressBar } from "react-bootstrap";
import Percentage from "../../../Plugin/Snippet/Percentage";
import { Formatter } from "../../../Packages/DynamicWidget/Helpers/FormatHelper";

function getReceivedDate(params) {
  return `${moment(params.row.FullDate).format("MMMM Do, YYYY")}`;
}

function getShortDate(params) {
  return `${moment(params.row.IssueDate).format("MMM Do, YYYY")}`;
}

function getPOShortDate(params) {
  return `${moment(params.row.PurchaseOrderDate).format("MMM Do, YYYY")}`;
}

function getRegionZoneWoreda(params) {
  return `${params.row.Region} - ${params.row.Zone} - ${params.row.Woreda}`;
}

export const MANUFACTURER_COLUMN = [
    {
      field: "RowNumber",
      renderHeader: (params) => <strong>{" "}</strong>,
      width: 50,
    },
    {
      field: "Manufacturer",
      renderHeader: (params) => <strong>{"Manufacturer"}</strong>,
      renderCell: (params) => <span>{params.row.Manufacturer} &nbsp;&nbsp;&nbsp;<a href={params.row.Website} target="_blank"><FiExternalLink size={17} /></a></span>,
      flex: 1,
      minWidth: 100
    },
    {
      field: "Country",
      renderHeader: (params) => <strong>{"Country"}</strong>,
      flex: 1,
      minWidth: 100
    },
    {
      field: "AmountBirr",
      renderHeader: (params) => <strong>{"Value (ETB)"}</strong>,
      flex: 1,
      type: "decimalZero",
      maxWidth: 200,
      minWidth: 75
    },
    {
      field: "ReceivedbyAllRecipants",
      renderHeader: (params) => <strong>{"Share"}</strong>,
      flex: 1,
      valueGetter: getShare,
      maxWidth: 200
    },
    {
      field: "Items",
      headerName: " ",
      renderCell: (params) => <ExpandManufacturer params={params} />,
      maxWidth: 75
    },
  ];

export const MANUFACTURER_LLIN_COLUMN = [
  { field: "RowNumber", headerName: " ", width: 50 },
  {
    field: "PurchaseOrderNumber",
    renderHeader: (params) => <strong>{"PO. Number"}</strong>,
    flex: 1,
  },
  {
    field: "Donor",
    renderHeader: (params) => <strong>{"Donor"}</strong>,
    flex: 1,
  },
  {
    field: "Procurer",
    renderHeader: (params) => <strong>{"Procurer"}</strong>,
    flex: 1,
  },
  {
    field: "purchaseOrderDate",
    renderHeader: (params) => <strong>{"Order Date"}</strong>,
    flex: 1,
    valueFormatter: getGShortDate,
  },
  {
    field: "supplier",
    renderHeader: (params) => <strong>{"Supplier"}</strong>,
    flex: 1,
  },
  {
    field: "QuantityPurchaseOrder",
    renderHeader: (params) => <strong>{"Ordered"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "QuantityInvoiced",
    renderHeader: (params) => <strong>{"Shipped"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "QuantityReceived",
    renderHeader: (params) => <strong>{"Received"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "PendingQuantity",
    renderHeader: (params) => <strong>{"Pending"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "ReceivedPercentage", 
    renderHeader: (params) => <strong>{"Receive Completed"}</strong>,  
    flex: 1, 
    renderCell: (params) => <Box sx={{ width: '100%', marginTop: 2 }}>
      <ProgressBar now={FormatDecimal(100- params.row.Pending)} label={`${FormatDecimal(100 - params.row.Pending)}%`} />
    </Box> 
  },
];

export const DISTRIBUTION_COLUMN = [
  { field: "RowNumber", headerName: " ", width: 50 },
  {
    field: "Region",
    renderHeader: (params) => <strong>{"Region"}</strong>,
    flex: 1,
  },
  {
    field: "Zone",
    renderHeader: (params) => <strong>{"Zone"}</strong>,
    flex: 1,
  },
  {
    field: "District",
    renderHeader: (params) => <strong>{"Woreda"}</strong>,
    flex: 1,
  },
  {
    field: "PlannedQuantity",
    renderHeader: (params) => <strong>{"Planned Qty."}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "QuantityIssued",
    renderHeader: (params) => <strong>{"Actual Qty."}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "FillRate",
    renderHeader: (params) => <strong>{"Fill rate"}</strong>,
    flex: 1,
    valueFormatter: (params) => { return (params.value===null || params.value===undefined)?'Nav':(FormatDecimal(params.value, 2))+'%'},
  },
];

export const STOCK_ON_HAND_COLUMN = [
  { field: "RowNumber", headerName: " ", width: 15 },
  {
    field: "Environment",
    renderHeader: (params) => <strong>{"Site"}</strong>,
    width: 150,
  },
  {
    field: "ProductSN",
    renderHeader: (params) => <strong>{"EC."}</strong>,
    width: 150,
  },
  {
    field: "Imp-Nxt",
    renderHeader: (params) => <strong>{"Imp-Nxt"}</strong>,
    width: 150,
  },
  {
    field: "MCn",
    renderHeader: (params) => <strong>{"MCn"}</strong>,
    width: 150,
  },
  {
    field: "COC.",
    renderHeader: (params) => <strong>{"COC."}</strong>,
    width: 150,
  },
  {
    field: "IUD.",
    renderHeader: (params) => <strong>{"IUD."}</strong>,
    width: 150,
  },
  {
    field: "POP.",
    renderHeader: (params) => <strong>{"POP."}</strong>,
    width: 150,
  },
  {
    field: "Medroxy",
    renderHeader: (params) => <strong>{"Medroxy"}</strong>,
    width: 150,
  },
  {
    field: "Jadelle1",
    renderHeader: (params) => <strong>{"Jadelle1"}</strong>,
    width: 150,
  },
  {
    field: "PP-IUCD 1",
    renderHeader: (params) => <strong>{"PP-IUCD 1"}</strong>,
    width: 150,
  },
];

export const STOCK_STATUS_COLUMN = [
  {
    field: "ProductCN",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    flex: 1,
  },
  {
    field: "SS",
    renderHeader: (params) => <strong>{"Status"}</strong>,
    flex: 1,
    cellClassName: (params) =>
      clsx({
        normal: params.value === 'Normal',
        excess: params.value === 'Excess',
        belowMin: params.value === 'Below Min',
        stockedOut: params.value === 'Stocked Out',
        belowEOP: params.value === 'Below EOP',
      }),
  },
  {
    field: "SOH",
    renderHeader: (params) => <strong>{"SOH"}</strong>,
    flex: 1,
    valueFormatter: (params) => { return Formatter(params?.value); }
  },
  {
    field: "MOS",
    renderHeader: (params) => <strong>{"MOS"}</strong>,
    flex: 1,
    type: "decimal",
  },
  {
    field: "QuantityPurchaseOrder",
    renderHeader: (params) => <div><strong>{"Planned"}</strong><br/><span> (in MOS)</span></div>,
    flex: 1,
    renderCell: (params) => <div><div>{FormatDecimal(params.row.QuantityPurchaseOrder + params.row.GIT, 0)}</div> <div>({params.row.AMC? FormatDecimal((params.row.QuantityPurchaseOrder + params.row.GIT)/params.row.AMC): 0})</div></div>,
  },
  {
    field: "GIT",
    renderHeader: (params) => <div><strong>{"GIT"}</strong><br/><span> (in MOS)</span></div>,
    flex: 1,
    renderCell: (params) => <div><div>{FormatDecimal(params.row.GIT, 0)}</div> <div>({params.row.AMC? FormatDecimal((params.row.GIT)/params.row.AMC): 0})</div></div>,
  },
  {
    field: "AMC",
    renderHeader: (params) => <strong>{"AMC"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "Min",
    renderHeader: (params) => <strong>{"Min"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "Max",
    renderHeader: (params) => <strong>{"Max"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
];

export const GetStockStatusColumnExpand = function(programCode) {
  let columns = STOCK_STATUS_COLUMN_EXPAND;

  if(programCode == 'VAC') {
    columns[4].renderHeader = 
        (params) => 
        <div title="Normal MOS range: 4-10 months">
          <strong>{"MOS"}</strong><br/>
          <span> [4- 10]</span>
        </div>
  }

  return columns;
}

export const STOCK_STATUS_COLUMN_EXPAND = [
  {
    field: "ProductCN",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    minWidth: 100,
    flex: 1
  },
  {
    field: "SS",
    renderHeader: (params) => <strong>{"Status"}</strong>,
    cellClassName: (params) =>
      clsx({
        normal: params.value === 'Normal',
        excess: params.value === 'Excess',
        belowMin: params.value === 'Below Min',
        stockedOut: params.value === 'Stocked Out',
        belowEOP: params.value === 'Below EOP',
      }),
      minWidth: 75,
      flex: 1
  },
  {
    field: "SOH",
    renderHeader: (params) => <strong>{"SOH"}</strong>,
    type: "decimalZero",
    minWidth: 75,
    flex: 1
  },
  {
    field: "AMC",
    renderHeader: (params) => <strong>{"AMC"}</strong>,
    minWidth: 75,
    flex: 1,
    type: "decimalZero",
    renderCell: (params) => 
        <div className="gray-out">{FormatDecimal(params.row.AMC, 0)}</div>
  },
  {
    field: "MOS",
    renderHeader: (params) => 
        <div title="Normal MOS range: 6-13 months">
          <strong>{"MOS"}</strong><br/>
          <span> [6 - 13]</span>
        </div>,
    minWidth: 75,
    flex: 1,
    type: "decimal",
  },
  {
    field: "QuantityPurchaseOrder",
    renderHeader: (params) => <div title="Ordered in MOS (Quantity)"><strong>{"Ordered"}</strong><br/></div>,
    minWidth: 75,
    flex: 1,
    renderCell: (params) => 
      <div>
        <div>{params.row.AMC? FormatDecimal((params.row.QuantityPurchaseOrder)/params.row.AMC): 0}</div> 
        <div className="gray-out" title={FormatDecimal(params.row.QuantityPurchaseOrder, 0)}>({FormatDecimal(params.row.QuantityPurchaseOrder, 0)})</div>
      </div>
  },
  {
    field: "GIT",
    renderHeader: (params) => <div title="GIT in MOS (Quantity)"><strong>{"GIT"}</strong><br/></div>,
    minWidth: 75,
    flex: 1,
    renderCell: (params) => 
        <div>
          <div>{params.row.AMC? FormatDecimal((params.row.GIT)/params.row.AMC): 0}</div> 
          <div className="gray-out">({FormatDecimal(params.row.GIT, 0)})</div>
        </div>
  },
  {
    field: "Min",
    renderHeader: (params) => <strong>{"Min"}</strong>,
    minWidth: 75,
    flex: 1,
    type: "decimalZero",
    renderCell: (params) => 
        <div className="gray-out">{FormatDecimal(params.row.Min, 0)}</div>
  },
  {
    field: "Max",
    renderHeader: (params) => <strong>{"Max"}</strong>,
    minWidth: 75,
    flex: 1,
    type: "decimalZero",
    renderCell: (params) => 
        <div className="gray-out">{FormatDecimal(params.row.Max, 0)}</div>
  },
  // {
  //   field: "Items",
  //   headerName: " ",
  //   renderCell: (params) => <ExpandItem params={params} />,
  //   width: 100,
  // },
];

export const PO_SHIMPENT_COLUMN = [
  { field: "PurchaseOrderNumber", renderHeader: (params) => <strong>{"PO. Number"}</strong>, flex: 1},
  { field: "purchaseOrderDate", renderHeader: (params) => <strong>{"Date"}</strong>,  flex: 1, valueGetter: getPOShortDate },
  { field: "ProductCN", renderHeader: (params) => <strong>{"Item"}</strong>,  flex: 1 },
  // { field: "Donor", renderHeader: (params) => <strong>{"Funding source"}</strong>,  flex: 1 },
  { field: "Procurer", renderHeader: (params) => <strong>{"Procurer"}</strong>,  flex: 1 },
  // { 
  //   field: "Supplier", 
  //   renderHeader: (params) => <strong>{"Supplier"}</strong>,  
  //   flex: 1,
  //   renderCell: (params) => (<LightTooltip title={`Manufacturer: ${params.row.Manfuacturer}`}>
  //       <div style={{overflow: "hidden", textOverflow: "ellipsis"}}>
  //         {params.row.Supplier}
  //       </div>
  //     </LightTooltip>),
  // },
  { field: "QuantityPurchaseOrder", renderHeader: (params) => <strong>{"Planned"}</strong>,  flex: 1, valueFormatter: (params) => { return Formatter(params?.value); } },
  { field: "QuantityInvoiced", renderHeader: (params) => <strong>{"GIT"}</strong>,  flex: 1, renderCell: (params) => <div><div>{FormatDecimal(params.row.QuantityInvoiced, 0)}</div> <div>({FormatDecimal((100*params.row.QuantityInvoiced)/params.row.QuantityPurchaseOrder)}%)</div></div>, },
  { field: "QuantityReceived", renderHeader: (params) => <strong>{"Received"}</strong>,  flex: 1, valueFormatter: (params) => { return Formatter(params?.value); } },
  { field: "Pending", renderHeader: (params) => <strong>{"Receive Completed"}</strong>,  flex: 1, 
    renderCell: (params) => <Box sx={{ width: '100%', marginTop: 2 }}>
      <ProgressBar now={FormatDecimal(100- params.row.Pending)} label={`${FormatDecimal(100 - params.row.Pending)}%`} />
    </Box> },
];

export const SAP_PO_SHIMPENT_COLUMN = [
  { field: "PurchaseOrderNumber", renderHeader: (params) => <strong>{"PO. Number"}</strong>, flex: 1},
  { field: "ProductCN", renderHeader: (params) => <strong>{"Item"}</strong>, flex: 1 },
  { field: "Donor", renderHeader: (params) => <strong>{"Funding source"}</strong>,  flex: 1 },
  { field: "OrderQuantity", renderHeader: (params) => <strong>{"Ordered"}</strong>,  flex: 1, valueFormatter: (params) => { return Formatter(params?.value); } },
  { field: "NextDeliveryQuantity", renderHeader: (params) => <strong>{"Next Delivery Quantity"}</strong>,  flex: 1, valueFormatter: (params) => { return Formatter(params?.value); } },
  { field: "NextDeliveryDate", renderHeader: (params) => <strong>{"Next Delivery Date"}</strong>, flex: 1, valueGetter: getShortDate},
  { field: "DeliveredQuantity", renderHeader: (params) => <strong>{"Delivered"}</strong>,  flex: 1, valueFormatter: (params) => { return Formatter(params?.value); } },
  { field: "Pending", renderHeader: (params) => <strong>{"Receive Completed"}</strong>,  flex: 1, 
     renderCell: (params) => <Box sx={{ width: '100%', marginTop: 2 }}>
       <ProgressBar now={FormatDecimal(100- params.row.Pending)} label={`${FormatDecimal(100 - params.row.Pending)}%`} />
     </Box> },
];

export const STOCK_STATUS_LLIN_COLUMN = [
  {
    field: "Environment",
    renderHeader: (params) => <strong>{"Site"}</strong>,
    width: 150,
  },
  {
    field: "SOH",
    renderHeader: (params) => <strong>{"SOH"}</strong>,
    width: 150,
  },
  {
    field: "QuantityPurchaseOrder",
    renderHeader: (params) => <strong>{"Ordered"}</strong>,
    width: 150,
  },
  {
    field: "GIT",
    renderHeader: (params) => <strong>{"GIT"}</strong>,
    width: 150,
  },
];

export const STOCK_STATUS_BLOOD_BANK_COLUMN = [
  {
    field: "ProductCN",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    flex: 1.5,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    flex: 1,
  },
  {
    field: "SS",
    renderHeader: (params) => <strong>{"Status"}</strong>,
    flex: 1,
    cellClassName: (params) =>
      clsx({
        normal: params.value === 'Normal',
        excess: params.value === 'Excess',
        belowMin: params.value === 'Below Min',
        stockedOut: params.value === 'Stocked Out',
        belowEOP: params.value === 'Below EOP',
      }),
  },
  {
    field: "SOH",
    renderHeader: (params) => <strong>{"SOH"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "AMC",
    renderHeader: (params) => <strong>{"AMC"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "MOS",
    renderHeader: (params) => <strong>{"MOS"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "Min",
    renderHeader: (params) => <strong>{"Min"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "Max",
    renderHeader: (params) => <strong>{"Max"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "Items",
    headerName: " ",
    renderCell: (params) => <Box display="flex" style={{padding: "8px 0"}}><Box style={{marginRight: "10px" }}><ExpandExpiry params={params} /></Box><Box ><ExpandQuantity params={params} /></Box></Box>,
    width: 150,
  },
];
export const ISSUED_COLUMN = [
  { field: "FullItemName", renderHeader: (params) => <strong>{"Item"}</strong>, flex: 1.5 },
  { field: "Institution", renderHeader: (params) => <strong>{"Hub"}</strong>, flex: 1 },
  { field: "QuantityIssued", renderHeader: (params) => <strong>{"Quantity"}</strong>, flex: 0.9, type: 'decimalZero' },
  { field: "Supplier", renderHeader: (params) => <strong>{"Quantity"}</strong>, flex: 1 },
  { field: "InvoiceNumber", renderHeader: (params) => <strong>{"Invoice"}</strong>, flex: 0.8 },
  { field: "IssueDate", renderHeader: (params) => <strong>{"Date"}</strong>, flex: 1, valueGetter: getShortDate},
  { field: "Region", renderHeader: (params) => <strong>{"Region-Zone-Woreda"}</strong>, flex: 1 },
  { field: "AmountIssued", renderHeader: (params) => <strong>{"Amount (ETB)"}</strong>, flex: 1, type: 'decimalZero' },
];

export const SHI_ITEM_COLUMN = [
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Product"}</strong>,
    flex: 1.5,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    flex: 1,
  },
  {
    field: "CommodityTypeCode",
    renderHeader: (params) => <strong>{"Commodity Type"}</strong>,
    flex: 1,
  },
  {
    field: "BB",
    renderHeader: (params) => <strong>{"Beginning Balance"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "EB",
    renderHeader: (params) => <strong>{"Ending Balance"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "Received",
    renderHeader: (params) => <strong>{"Received"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "Issued",
    renderHeader: (params) => <strong>{"Issued"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "AMC",
    renderHeader: (params) => <strong>{"AMC"}</strong>,
    flex: 1,
    type: "decimalZero",
  },
  {
    field: "MOS",
    renderHeader: (params) => <strong>{"MOS"}</strong>,
    flex: 1,
    type: "decimal",
  },
  {
    field: "Items",
    headerName: " ",
    renderCell: (params) => <ExpandProduct params={params} />,
    width: 100,
  },
];

export const SUPPLIER_COLUMN = [
  { field: "RowNumber", headerName: " ", width: 50 },
  {
    field: "supplierName",
    renderHeader: (params) => <strong>{"Supplier"}</strong>,
    flex: 1.5,
  },
  {
    field: "AmountBirr",
    renderHeader: (params) => <strong>{"Value (ETB)"}</strong>,
    flex: 1,
    type: 'decimalZero'
  },
  {
    field: "ReceivedbyAllRecipants",
    renderHeader: (params) => <strong>{"Share"}</strong>,
    flex: 1,
    valueFormatter: getAllShare,
  },
];

export const COUNTRY_COLUMN = [
  { field: "RowNumber", headerName: " ", width: 50 },
  {
    field: "Country",
    renderHeader: (params) => <strong>{"Country"}</strong>,
    flex: 1,
    minWidth: 100
  },
  {
    field: "AmountBirr",
    renderHeader: (params) => <strong>{"Value (ETB)"}</strong>,
    flex: 1,
    type: "decimalZero",
    maxWidth: 200,
    minWidth: 75
  },
  {
    field: "ReceivedbyAllRecipants",
    renderHeader: (params) => <strong>{"Share"}</strong>,
    flex: 1,
    valueGetter: getShare,
    maxWidth: 200
  },
  {
    field: "Items",
    headerName: " ",
    renderCell: (params) => <ExpandCountry params={params} />,
    flex: 1,
    maxWidth: 75
  },
];

export const RECEIVED_COLUMN = [
  { field: "RowNumber", renderHeader: (params) => <strong>{" "}</strong>, width: 50 },
  { field: "FullDate", renderHeader: (params) => <strong>{"Date"}</strong>, flex: 1, valueGetter: getReceivedDate },
  { field: "ProductCN", renderHeader: (params) => <strong>{"Item"}</strong>, flex: 1 },
  // { field: "Supplier", renderHeader: (params) => <strong>{"Supplier"}</strong>, flex: 1 },
  { field: "Manufacturer", renderHeader: (params) => <strong>{"Manufacturer"}</strong>, flex: 1 },
  { field: "Country", renderHeader: (params) => <strong>{"Country"}</strong>, flex: 1 },
  { field: "QuantityReceived", renderHeader: (params) => <strong>{"Quantity"}</strong>, flex: 1, type: 'decimalZero' },
  { 
    field: "AmountReceivedBirr", 
    renderHeader: (params) => <strong>{"Value (ETB)"}</strong>, 
    flex: 1,
    renderCell: (params) => (<LightTooltip title={`Funding Source: ${params.row.FundingSource}`}>
        <div>
          {FormatDecimal(params.row.AmountReceivedBirr, 0)}
        </div>
      </LightTooltip>),
  },
];

export const ALTERNATE_ITEM_COLUMN = [
  { field: "AlternateItemSN", renderHeader: (params) => <strong>{"Alternate SN"}</strong>, width: 150 },
  { field: "AlternateItem", renderHeader: (params) => <strong>{"Default Item"}</strong>, flex: 1.5 },
  { field: "DefaultUnit", renderHeader: (params) => <strong>{"Default Unit"}</strong>, flex: 1 },
  { field: "DefaultQuantity", renderHeader: (params) => <strong>{"Default Quantity"}</strong>, flex: 1 },
  { field: "ItemSN", renderHeader: (params) => <strong>{"Item SN"}</strong>, flex: 1 },
  { field: "Item", renderHeader: (params) => <strong>{"Item"}</strong>, flex: 1.5 },
  { field: "Unit", renderHeader: (params) => <strong>{"Unit"}</strong>, flex: 1 },
  { field: "Quantity", renderHeader: (params) => <strong>{"Quantity"}</strong>, flex: 1, type: 'decimalZero' },
];

export const ITEM_DISTRIBUTION_COLUMN = [
  { field: "RowNumber", renderHeader: (params) => <strong>{" "}</strong>, width: 50 },
  { field: "IssueDate", renderHeader: (params) => <strong>{"Date"}</strong>, flex: 1, valueGetter: getShortDate, },
  { field: "Region", renderHeader: (params) => <strong>{"Region-Zone-Woreda"}</strong>, flex: 1.4, valueGetter: getRegionZoneWoreda },
  { field: "Item", renderHeader: (params) => <strong>{"Item"}</strong>, flex: 0.8 },
  // {
  //   field: "InstitutionSN",
  //   headerName: " ",
  //   renderCell: (params) => <ExpandIssueTrend params={params} />,
  //   width: 75,
  // },
  { 
    field: "Institution", 
    renderHeader: (params) => <strong>{"Facility"}</strong>, 
    flex: 1, 
    renderCell: (params) => (<LightTooltip title={`Manufacturer: ${params.row.Manufacturer}`}>
        <div>
          {params.row.Institution}
        </div>
      </LightTooltip>) },
  { field: "QuantitySupplied", renderHeader: (params) => <strong>{"Quantity"}</strong>, flex: 0.7, type: 'decimalZero' },
  { field: "InvoiceNumber", renderHeader: (params) => <strong>{"Invoice"}</strong>, flex: 0.7 },
  { field: "TotalIssued", renderHeader: (params) => <strong>{"Issued 12"}</strong>, flex: 1, type: 'decimalZero' },
  // { field: "Supplier", renderHeader: (params) => <strong>{"Distributor"}</strong>, flex: 1 },
];

export const NG_SUPPLIERS_COLUMN = [
  { 
    field: "RowNumber", 
    flex: 1.3, 
    renderHeader: (params) => <strong>{" "}</strong>, width: 50,
    renderCell: (params) => <span>
          { params.row.Logo ? 
              <img src={params.row.Logo} alt="" 
                  style={{ maxHeight: "1.5rem", background: "white" }} /> : 
              <span></span>}
        </span>
  },
    
  { 
    field: "Agent", 
    renderHeader: (params) => <strong>{"Agent"}</strong>, 
    flex: 1.2, 
    renderCell: (params) => <span><span>{params.value}</span><span title="Major Agent">{params.row.IsMajorAgent ? "*" : ""} </span></span>
  },
  { field: "COC", 
    renderHeader: (params) => <strong>{"COC."}</strong>, 
    flex: 1, 
    type: "decimalZero",
    renderCell: (params) => 
        <Percentage value={params.value} 
            percentValue={params.row.COCPercentage}></Percentage>
  },
  { 
    field: "EC", 
    renderHeader: (params) => <strong>{"EC."}</strong>, 
    flex: 1, 
    type: "decimalZero",
    renderCell: (params) => 
        <Percentage value={params.value} 
            percentValue={params.row.ECPercentage}></Percentage>
  },
  { 
    field: "IMPNxt", 
    renderHeader: (params) => <strong>{"Imp-Nxt"}</strong>, 
    flex: 1, 
    type: "decimalZero",
    renderCell: (params) => 
        <Percentage value={params.value} 
            percentValue={params.row.IMPNxtPercentage}></Percentage> 
  },
  { 
    field: "IUD", 
    renderHeader: (params) => <strong>{"IUD."}</strong>, 
    flex: 1, 
    type: "decimalZero",
    renderCell: (params) => 
        <Percentage value={params.value} 
            percentValue={params.row.IUDPercentage}></Percentage>  
  },
  { 
    field: "Jadelle1", 
    renderHeader: (params) => <strong>{"Jadelle1"}</strong>, 
    flex: 1, 
    type: "decimalZero",
    renderCell: (params) => 
        <Percentage value={params.value} 
            percentValue={params.row.Jadelle1Percentage}></Percentage>  
  },
  { 
    field: "Condom", 
    renderHeader: (params) => <strong>{"MCn"}</strong>, 
    flex: 1, 
    type: "decimalZero",
    renderCell: (params) => 
        <Percentage value={params.value} 
            percentValue={params.row.CondomPercentage}></Percentage>  
  },
  { 
    field: "Medroxy", 
    renderHeader: (params) => <strong>{"Medroxy"}</strong>, 
    flex: 1, 
    type: "decimalZero",
    renderCell: (params) => 
        <Percentage value={params.value} 
            percentValue={params.row.MedroxyPercentage}></Percentage>  
  },
  { 
    field: "POP", 
    renderHeader: (params) => <strong>{"POP."}</strong>, 
    flex: 1, 
    type: "decimalZero",
    renderCell: (params) => 
        <Percentage value={params.value} 
            percentValue={params.row.POPPercentage}></Percentage>  
  },
  { 
    field: "PPIUCD1", 
    renderHeader: (params) => <strong>{"PP-IUCD 1"}</strong>, 
    flex: 1, 
    valueFormatter: (params) => { return Formatter(params?.value); } ,
    renderCell: (params) => 
        <Percentage value={params.value} 
            percentValue={params.row.PPIUCD1Percentage}></Percentage> 
  },
];

export const STAGING_ISSUE_COLUMN = [
  { field: "Item", renderHeader: (params) => <strong>{"Item"}</strong>, flex: 1.5 },
  { field: "Unit", renderHeader: (params) => <strong>{"Unit"}</strong>, flex: 1 },
  { field: "Institution", renderHeader: (params) => <strong>{"Hub"}</strong>, flex: 1 },
  { field: "IssuedQuantity", renderHeader: (params) => <strong>{"Quantity"}</strong>, flex: 1 },
  { field: "Environment", renderHeader: (params) => <strong>{"Supplier"}</strong>, flex: 1 },
  { field: "InvoiceNumber", renderHeader: (params) => <strong>{"Invoice"}</strong>, flex: 1 },
  { field: "IssueDate", renderHeader: (params) => <strong>{"Date"}</strong>, flex: 1, valueGetter: (params) => (`${moment(params.value).format("MMM Do, YYYY")}`) },
  { field: "Region", renderHeader: (params) => <strong>{"Region-Zone-Woreda"}</strong>, flex: 1 },
  { field: "IssuedAmount", renderHeader: (params) => <strong>{"Amount (ETB)"}</strong>, flex: 1, type: 'decimalZero' },
];

export const STAGING_SOH_COLUMN = [
  { field: "Item", renderHeader: (params) => <strong>{"Item"}</strong>, flex: 1 },
  { field: "Unit", renderHeader: (params) => <strong>{"Unit"}</strong>, flex: 1 },
  { field: "Environment", renderHeader: (params) => <strong>{"Environment"}</strong>, flex: 1 },
  { field: "SOH", renderHeader: (params) => <strong>{"SOH"}</strong>, flex: 1, type: 'decimalZero' },
  { field: "SOHAmount", renderHeader: (params) => <strong>{"SOH Amount"}</strong>, flex: 1, type: 'decimalZero' },
];

const columns = [
  STOCK_ON_HAND_COLUMN,
  ISSUED_COLUMN,
  MANUFACTURER_COLUMN,
  SUPPLIER_COLUMN,
  COUNTRY_COLUMN,
  STOCK_STATUS_COLUMN,
];

export default columns;

const ExpandManufacturer = ({params}) => {
  const setManufacturerItemsModalOpen = useFanosStore((state) => state.setManufacturerItemsModalOpen);
  const setModalManufacturer = useFanosStore((state) => state.setModalManufacturer);

  const handleManufacturerDetailsClicked = (manuID) => {
    setManufacturerItemsModalOpen(true)
    setModalManufacturer(manuID)
  }
    
  return (<>
    <Button type="submit" 
      style={{ 
        backgroundColor: "#c4b8a6",
        minWidth: "unset",
      }}
      variant="contained" 
      onClick={() => handleManufacturerDetailsClicked(params.row.ManufacturerID)}
    >
      <FaExpandAlt />
    </Button>
  </>);  
}

const ExpandCountry = ({params}) => {
  const setCountryItemsModalOpen = useFanosStore((state) => state.setCountryItemsModalOpen);
  const setModalCountry = useFanosStore((state) => state.setModalCountry);

  const handleCountryDetailsClicked = (countryCode) => {
    setCountryItemsModalOpen(true)
    setModalCountry(countryCode)
  }
    
  return (<>
    <Button type="submit" 
      style={{ 
        backgroundColor: "#c4b8a6",
        minWidth: "unset",
      }}
      variant="contained" 
      onClick={() => handleCountryDetailsClicked(params.row.Alpha3code)}
    >
      <FaExpandAlt />
    </Button>
  </>);  
}

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      border: '1px solid black',
      boxShadow: theme.shadows[1],
      fontSize: 13,
  },
}));

const ExpandProduct = ({params}) => {
  const setShiProductModalOpen = useFanosStore((state) => state.setShiProductModalOpen);
  const setModalProduct = useFanosStore((state) => state.setModalProduct);

  const handleShiDetailsClicked = (productCode) => {
    setShiProductModalOpen(true)
    setModalProduct(productCode)
  }
    
  return (<>
    <Button type="submit" 
      style={{ 
        backgroundColor: "#c4b8a6",
        minWidth: "unset",
      }}
      variant="contained" 
      onClick={() => handleShiDetailsClicked(params.row)}
    >
      <FaExpandAlt />
    </Button>
  </>);  
}

const ExpandItem = ({params}) => {
  const setStockItemOpen = useFanosStore((state) => state.setStockItemModalOpen);
  const setModalItem = useFanosStore((state) => state.setModalItem);

  const handleItemDetailsClicked = (productSN) => {
    setStockItemOpen(true)
    setModalItem(productSN)
  }
    
  return (<>
    <Button type="submit" 
      style={{ 
        backgroundColor: "#c4b8a6",
        minWidth: "unset",
      }}
      variant="contained" 
      onClick={() => handleItemDetailsClicked(params.row.ProductSN)}
    >
      <FaExpandAlt />
    </Button>
  </>);  
}



const ExpandIssueTrend = (params) => {
  const setIssueTrendModalOpen = useFanosStore((state) => state.setIssueTrendModalOpen);
  const setIssueTrendModalData = useFanosStore((state) => state.setIssueTrendModalData);

  const handleItemDetailsClicked = () => {
    setIssueTrendModalOpen(true);
    setIssueTrendModalData( { row: params.params.row } );
  }
    
  return (<>
    <Button type="submit" 
      style={{ 
        backgroundColor: "#c4b8a6",
        minWidth: "unset",
      }}
      variant="contained" 
      onClick={() => handleItemDetailsClicked()}
    >
      <FaExpandAlt />
    </Button>
  </>);  
}

const ExpandExpiry = ({params}) => {
  const setStockExpiryOpen = useFanosStore((state) => state.setStockExpiryModalOpen);
  const setModalExpiry = useFanosStore((state) => state.setModalExpiry);

  const handleStockExpiryClicked = (productSN) => {
    setStockExpiryOpen(true)
    setModalExpiry(productSN)
  }
    
  return (<>
    <Button type="submit" 
      style={{ 
        backgroundColor: "#c4b8a6",
        minWidth: "unset",
      }}
      variant="contained" 
      onClick={() => handleStockExpiryClicked(params.row.ProductSN)}
    >
      <FaExpandAlt />
    </Button>
  </>);  
}

const ExpandQuantity = ({params}) => {
  const setStockQuantityOpen = useFanosStore((state) => state.setStockQuantityModalOpen);
  const setModalQuantity = useFanosStore((state) => state.setModalQuantity);

  const handleStockQuantityClicked = (productSN) => {
    setStockQuantityOpen(true)
    setModalQuantity(productSN)
  }
    
  return (<>
    <Button type="submit" 
      style={{ 
        backgroundColor: "orange",
        minWidth: "unset",
      }}
      variant="contained" 
      onClick={() => handleStockQuantityClicked(params.row.ProductSN)}
    >
      <FaExpandAlt />
    </Button>
  </>);  
}
