import React, {} from "react";
import NavBar from "../../shared/NavBar";
import Footer from "../../shared/Footer";
import { HUB_SOH_API, ITEM_EXPIRY_API, LOCAL_PO_API, LOCAL_PO_RECEIVES_API, PSM_CENTER_TO_HUB_API, PSM_HUB_TO_FACILITY_API } from "../../shared/Shared Utils/AppData";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import styled from "styled-components";
import moment from "moment";
import { HUB_VITAL_ENVIRONMENT_SELECT_CONFIG, PROGRAM_SELECT_CONFIG, QUARTER_SELECT_CONFIG, YEAR_SELECT_CONFIG } from "../../shared/Shared Utils/DataTableConstants";
import { ENVIRONMENT_SELECT_CONFIG } from "../../shared/Shared Utils/DataTableConstants";
import { Box, Button, FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import { getGShortDate } from "../../../Utils/NumberUtils";

function getIndex(params) {
  return `${params.row.autoGeneratedId + 1}`;
}

const LOCAL_PO_RECEIVES_COLUMN = [
  {
    field: "SN",
    renderHeader: (params) => <strong>{"SN"}</strong>,
    width: 60,
    valueGetter: getIndex,
  },
  {
    field: "PurchaseOrderNumber",
    renderHeader: (params) => <strong>{"PO Number"}</strong>,
    flex: 0.7,
  },
  {
    field: "Item",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    flex: 1.5,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    flex: 0.8,
  },
  {
    field: "GRNF",
    renderHeader: (params) => <strong>{"GRNF"}</strong>,
    flex: 0.6,
  },
  {
    field: "GRV",
    renderHeader: (params) => <strong>{"GRV"}</strong>,
    flex: 0.6,
  },
  {
    field: "InvoiceNumber",
    renderHeader: (params) => <strong>{"Invoice Number"}</strong>,
    flex: 1,
  },
  {
    field: "Program",
    renderHeader: (params) => <strong>{"Program"}</strong>,
    flex: 1,
  },
  {
    field: "Supplier",
    renderHeader: (params) => <strong>{"Supplier"}</strong>,
    flex: 1,
  },
  {
    field: "Environment",
    renderHeader: (params) => <strong>{"Environment"}</strong>,
    flex: 1,
  },
  {
    field: "Manufacturer",
    renderHeader: (params) => <strong>{"Manufacturer"}</strong>,
    flex: 1,
  },
  {
    field: "ReceivedQuantity",
    renderHeader: (params) => <strong>{"Received Quantity"}</strong>,
    flex: 1,
    type: 'decimalZero',
  },
  {
    field: "ReceivedDate",
    renderHeader: (params) => <strong>{"Received Date"}</strong>,
    flex: 1,
    valueFormatter: getGShortDate,
  },
];

const LocalPOReceive = () => {
  const [POList, setPOList] = useState([null]);
  const [POListTemp, setPOListTemp] =useState([null]);

  function loadFilterToDefaultParams(filterParams, dateFilterFlag) {
    return filterParams;
  }
  return (
    <Wrapper>
      {" "}
      <NavBar title={`Fanos LOCAL PURCHASE ORDER RECEIVES Dashboard`} />
      <div className="card-container" style={{display: "flex", margin: "0 3.3rem", padding: '2rem 0'}}>
        <Box>
           <Box style={{fontSize: '16px', fontWeight: '600'}}>Purchase Order Numbers:</Box>
        </Box>
        <Box display="flex" style={{flexDirection: 'column', marginLeft: '15px'}}>
           {POListTemp.map((PO, i) => (
              <POInput
                 type="number"
                 name="poNumber"
                 id="poNumber"
                 onChange={(e) =>
                   setPOListTemp(POListTemp.map((p) => p === PO && e.target.value !== ''? `'${e.target.value}'`: p === PO && e.target.value !== ''? null: p))
                 }
               />
           ))}
           <AddButton variant="outlined" onClick={() => setPOListTemp([...POListTemp, null])}><IoAddOutline /> Add</AddButton>
        </Box>
        <button
          className="button"
          onClick={() => {
            setPOList([...POListTemp]);
          }}
        >
          Apply
        </button>
      </div>
      <div className="grid-section">
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <CustomDataGrid
            component_config={{
              width: "90vw",
              title: "LOCAL PURCHASE ORDER RECEIVES",
              data_source_api: LOCAL_PO_RECEIVES_API,
              data_property: "Data",
              columns: LOCAL_PO_RECEIVES_COLUMN,
              isSOH: false,
              use_default_filter_params: false,
              defaultFilterParams: POList.some(element => element !== null)? {PurchaseOrderNumbers: POList.filter((pi) => pi !== null).map(item => `${item}`).join(',')}: {PurchaseOrderNumbers: `'0'`},
            }}
          />
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .button {
    border: 1px solid transparent;
    height: 2rem;
    width: 6rem;
    font-size: 14px;
    background-color: cadetblue;
    color: white;
    margin: 2rem 1rem 2rem 3rem;
    border-radius: 4px;
  }
`;

const POInput = styled.input`
  border: 1px solid grey;
  padding: 5px 15px;
  width: 100%;
  font-size: 15px;
  margin-bottom: 8px;
  border-radius: 5px;
`;

const AddButton = styled(Button)`
  color: cadetblue;
  width: 50%;
`

export default LocalPOReceive;
