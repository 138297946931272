import React, { useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import Footer from "../../shared/Footer";
import styled from "styled-components";

import {
  getDistributionByFacilityType,
  ItemProcurer,
  ItemFunding,
  getMOSShareBySite,
  CashVsCredit,
  getFillRateGauge,
  getQRDistributionByCommodityType,
  getQRDistributionByRegionType,
  getQRDistributionByProgramType,
  getQRNearExpiry,
  getQRDistributionByHealthFacility,
  getQRDistributionByStockCommodityType,
  getQRNearExpiryAmount,
  getQRExpiredAmount,
} from "../../../Services/chartRequests";
import {
  getEnvironemntList,
  getHeaderInformation, getQuarterReportSummary,
} from "../../../Services/ApiManager";
import {
  STOCK_STATUS_API,
  CENTER_TO_HUB_API,
  FACILITY_COUNT_API,
  HUB_TO_CENTER_API,
  QR_DISTRIBUTION_BY_COMMODITY_API,
  QR_DISTRIBUTION_BY_OWNERSHIP_API,
  QR_PROCURED_LOCAL_API,
} from "../../shared/Shared Utils/AppData.js";

import CustomPieChart from "../../shared/Charts/CustomPieChart";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  STOCK_STATUS_COLUMN,
  ISSUED_COLUMN,
} from "../Program/ProgramsTableColumns";
import "react-datepicker/dist/react-datepicker.css";
import "../Program/style.css";
import CustomCard from "../../shared/Shared Utils/CustomCards";
import {
  CENTER_TO_HUB_DEFAULT_PARAM,
  COST_SELECT_CONFIG,
  ENVIRONMENT_SELECT_CONFIG,
  MODE_CODE_DEFAULT_PARAM,
  PO_PROCURER_SELECT_CONFIG,
  QR_CHART_TYPE_SELECT_CONFIG,
  QR_COMMODITY_DEFAULT_PARAM,
  QR_COST_SELECT_CONFIG,
  QR_EXPIRED_AMOUNT_DEFAULT_PARAM,
  QR_HEALTH_DEFAULT_PARAM,
  QR_NEAR_EXPIRY_AMOUNT_DEFAULT_PARAM,
  QR_NEAR_EXPIRY_DEFAULT_PARAM,
  QR_PROGRAM_TYPE_DEFAULT_PARAM,
  QR_REGION_DEFAULT_PARAM,
  QR_REPORT_DEFAULT_PARAM,
  QR_STOCK_COMMODITY_DEFAULT_PARAM,
  QR_TYPE_AMOUNT_SELECT_CONFIG,
  QR_TYPE_SELECT_CONFIG,
} from "../../shared/Shared Utils/DataTableConstants";
import {
  MdMedicalServices,
} from "react-icons/md";
import { HUB } from "../../shared/Shared Utils/InputConstants";
import { parseGridColumns, parseGridRows, } from "../../../Utils/ChartConverter";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ChartWrapper from "../../shared/Charts/ChartWrapper";
import sohPic from "../../../assets/cardIcons/soh.svg";
import stackPic from "../../../assets/cardIcons/stack.svg";
import gitPic from "../../../assets/cardIcons/git.svg";
import expPic from "../../../assets/cardIcons/expired.svg";
import { QR_DISTRIBUTION_BY_COMMODITY_COLUMN, QR_DISTRIBUTION_BY_OWNERSHIP_COLUMN, QR_PROCURED_LOCAL_COLUMN } from "./QuarterReportTableColumn";
import moment from "moment";
import CustomBarChart from "../../shared/Charts/CustomBarChartV2";
import CustomDatePicker from "../../shared/Shared Utils/CustomDatePicker";
import { FormControlLabel, Switch } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

export default function QuarterReport() {
  const history = useNavigate();
  const location = useLocation();
  let { ProgramName } = useParams();
  const [environmentList, setEnvironmentList] = useState([]);
  const [shareBySite, setShareBySite] = useState([]);
  const [modeCode, setModeCode] = useState("HPR");
  const [checked, setChecked] = useState(true);

  const [headerInfo, setHeaderInfo] = useState([]);

  const [headerInfoQuarterParams, setHeaderInfoQuarterParams] = useState(loadFilterToDefaultParams({...QR_REPORT_DEFAULT_PARAM},{ hasDateRangeFilter: true }));  
  const {data: { data: { Data:  headerInfoQuarter }} = { data: { Data: [] }}, isFetching: headerInfoQuarterLoading} = useQuery({
    queryKey: ['headerInfoQuarter', headerInfoQuarterParams],
    queryFn: () => getQuarterReportSummary(headerInfoQuarterParams),
    staleTime: 10800000,
  });

  const [distByCommodityParams, setDistByCommodityParams] = useState(loadFilterToDefaultParams({...QR_COMMODITY_DEFAULT_PARAM},{ hasDateRangeFilter: true }));  
  const {data: { data: { Data: distByCommodity }} = { data: { Data: [] }}, isFetching: distByCommodityLoading} = useQuery({
    queryKey: ['distByCommodity', distByCommodityParams],
    queryFn: () => getQRDistributionByCommodityType(distByCommodityParams),
    staleTime: 10800000,
  });

  const [distByRegionParams, setDistByRegionParams] = useState(loadFilterToDefaultParams({...QR_REGION_DEFAULT_PARAM}, { hasDateRangeFilter: true }));  
  const {data: { data: { Data: distByRegion }} = { data: { Data: [] }}, isFetching: distByRegionLoading} = useQuery({
    queryKey: ['distByRegion', distByRegionParams],
    queryFn: () => getQRDistributionByRegionType(distByRegionParams),
    staleTime: 10800000,
  });

  const [distByHealthParams, setDistByHealthParams] = useState(loadFilterToDefaultParams({...QR_HEALTH_DEFAULT_PARAM},{ hasDateRangeFilter: true }));  
  const {data: { data: { Data: distByHealth }} = { data: { Data: [] }}, isFetching: distByHealthLoading} = useQuery({
    queryKey: ['distByHealth', distByHealthParams],
    queryFn: () => getQRDistributionByHealthFacility(distByHealthParams),
    staleTime: 10800000,
  });

  const [distByStockCommodityParams, setDistByStockCommodityParams] = useState(loadFilterToDefaultParams({...QR_STOCK_COMMODITY_DEFAULT_PARAM},{ hasDateFilter: true }));  
  const {data: { data: { Data: distByStockCommodity }} = { data: { Data: [] }}, isFetching: distByStockCommodityLoading} = useQuery({
    queryKey: ['distByStockCommodity', distByStockCommodityParams],
    queryFn: () => getQRDistributionByStockCommodityType(distByStockCommodityParams),
    staleTime: 10800000,
  });

  const [distByProgramParams, setDistByProgramParams] = useState(loadFilterToDefaultParams({...QR_PROGRAM_TYPE_DEFAULT_PARAM},{ hasDateRangeFilter: true }));  
  const {data: { data: { Data: distByProgram }} = { data: { Data: [] }}, isFetching: distByProgramLoading} = useQuery({
    queryKey: ['distByProgram', distByProgramParams],
    queryFn: () => getQRDistributionByProgramType(distByProgramParams),
    staleTime: 10800000,
  });

  const [distByNearExpiryParams, setDistByNearExpiryParams] = useState(loadFilterToDefaultParams({...QR_NEAR_EXPIRY_DEFAULT_PARAM},{ hasDateFilter: true }));  
  const {data: { data: { Data: distByNearExpiry }} = { data: { Data: [] }}, isFetching: distByNearExpiryLoading} = useQuery({
    queryKey: ['distByNearExpiry', distByNearExpiryParams],
    queryFn: () => getQRNearExpiry(distByNearExpiryParams),
    staleTime: 10800000,
  });

  const [nearExpiryAmountParams, setNearExpiryAmountParams] = useState(loadFilterToDefaultParams({...QR_NEAR_EXPIRY_AMOUNT_DEFAULT_PARAM},{ hasDateFilter: true }));  
  const {data: { data: { Data: nearExpiryAmount }} = { data: { Data: [] }}, isFetching: nearExpiryAmountLoading} = useQuery({
    queryKey: ['nearExpiryAmount', nearExpiryAmountParams],
    queryFn: () => getQRNearExpiryAmount(nearExpiryAmountParams),
    staleTime: 10800000,
  });

  const [expiredAmountParams, setExpiredAmountParams] = useState(loadFilterToDefaultParams({...QR_EXPIRED_AMOUNT_DEFAULT_PARAM},{ hasDateRangeFilter: true }));  
  const {data: { data: { Data: expiredAmount }} = { data: { Data: [] }}, isFetching: expiredAmountLoading} = useQuery({
    queryKey: ['expiredAmount', expiredAmountParams],
    queryFn: () => getQRExpiredAmount(expiredAmountParams),
    staleTime: 10800000,
  });

  const [distribution, setDistribution] = useState("Center to Hub");  

  useState(shareBySite);
  const pageFilterParams = {};
  const filterParams = {};
  const handle_change = (param) => {
    pageFilterParams[param.paramName] = param.paramValue;
  };
  const select = [HUB];

  function loadFilterToDefaultParams(filterParams, dateFilterFlag) {
    if (dateFilterFlag.hasDateFilter) {
      let newDate = new Date();
      let day = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let currentDate = `${month}/${day}/${year}`;
      filterParams["TransactionDate"] = currentDate;
    }
    if (dateFilterFlag.hasDateRangeFilter) {
      const start = moment().subtract(3, 'months').format("MM/DD/YYYY");
      const end = moment().format("MM/DD/YYYY");
      filterParams["From"] = start;
      filterParams["To"] = end;
    }
    filterParams["ModeCode"] = modeCode;
    return filterParams;
  }

  const onChangeDatePicker = (dates) => {
    Object.keys(dates).forEach((key) => {
      QR_REPORT_DEFAULT_PARAM[key] = dates[key];
    });
    setHeaderInfoQuarterParams(QR_REPORT_DEFAULT_PARAM);
  };

  useEffect(() => {
    async function fetchData() {
      getEnvironemntList().then((data) => {
        setEnvironmentList([{Environment: "All", EnvironmentCode: ""}, ...data.data.Data]);
      });
    }
    fetchData();
  }, [modeCode, distribution]);

  useEffect(() => {
    if(headerInfoQuarter.length > 0){
      setHeaderInfo(Object.fromEntries(headerInfoQuarter.map(Object.values)));
    }
  }, [headerInfoQuarter]);


  return (
  <Wrapper>
    <div>
      {" "}
      <NavBar title={`Fanos Quarter Report Dashboard`} />
    </div>
    <div className="container-context mt-3">
      <div>
        <div className="card-container" style={{border: "1px solid lightgrey", padding: "10px 10px", alignItems: "center"}}>
          <div className="tool-bar-item-container" style={{width: "13rem"}}>
            <div className="tool-bar-item">
              <CustomDatePicker
                className="date-picker-input"
                handle_change={onChangeDatePicker}
                component_config={{
                    date_range: true,
                    start_date_param_name: "From",
                    end_date_param_name: "To",
                    quarter_report: true,
                  }}
              />
            </div>
          </div>
          <div className="tool-bar-item" style={{flexGrow: '0', width: '11rem'}}>
            <select
                className="select"
                style={{marginLeft: 0, marginBottom: 0, width: "10rem"}}
                onChange={(e) => setHeaderInfoQuarterParams(QR_REPORT_DEFAULT_PARAM.FROM?{EnvironmentCode: e.target.value, ...QR_REPORT_DEFAULT_PARAM}:loadFilterToDefaultParams(
                  {EnvironmentCode: e.target.value, ...QR_REPORT_DEFAULT_PARAM},
                  { hasDateRangeFilter: true }
                ))}
              >
                {environmentList.map((el) => (
                <option key={el.Environment} value={el.EnvironmentCode}>
                    {el.Environment}
                </option>
                ))}
            </select>
          </div>
          <>
            <CustomCard
              widget_config={{
                title: "SOH",
                value: headerInfo.SOHTotal ? headerInfo.SOHTotal.toLocaleString() : 0,
                logo_url: sohPic,
              }}
              Icon={MdMedicalServices}
            />
            <CustomCard
              widget_config={{
                title: "Distributed",
                value: headerInfo.DistributionTotal
                  ? headerInfo.DistributionTotal.toLocaleString()
                  : 0,
                logo_url: stackPic,
              }}
            />
            <CustomCard
              widget_config={{
                title: "Expired",
                value: headerInfo.ExpiredTotal
                  ? headerInfo.ExpiredTotal.toLocaleString()
                  : 0,
                logo_url: gitPic,
              }}
            />
            <CustomCard
              widget_config={{
                title: "Facilities Served",
                value: headerInfo.FacilityServedTotal
                  ? headerInfo.FacilityServedTotal.toLocaleString()
                  : 0,
                logo_url: expPic,
              }}
            />
          </>
          <FormControlLabel control={
              <Switch
                defaultChecked={true} 
                onChange={(e)=> {
                  setChecked(e.target.checked);
                  e.target.checked? setModeCode('HPR'): setModeCode('RDF');
                }} />} 
              label={checked?"HPR": "RDF"} />

          <button
              className="button"
              onClick={() => {
                history("/pfsaquarterreportraw");
              }}
            >
              Raw Data
            </button>
        </div>
        <div className="container-context">
          {" "}
          <GridContainer>
            <StandardChartWrapper>
              <ChartWrapper 
                title="Distribution by Commodity" 
                wgInfoId="wgQuarterReportDistributionByType"
                showYearFilter={false}
                selectFilterConfig={[QR_COST_SELECT_CONFIG,ENVIRONMENT_SELECT_CONFIG,]}
                defaultFilterParams={
                loadFilterToDefaultParams(
                  {...QR_COMMODITY_DEFAULT_PARAM},
                  { hasDateRangeFilter: true }
                )}
                datePicker={{
                  date_range: true,
                  start_date_param_name: "From",
                  end_date_param_name: "To",
                  quarter_report: true,
                }}
                isLoading={distByCommodityLoading}
                parameter={distByCommodityParams}
                setParameter={setDistByCommodityParams}
              >
                <CustomPieChart
                  data={distByCommodity}
                  label={"Commodity Type"}
                  fields={["AmountIssuedInBirr", "CommodityType"]}
                  style={{
                    position: "relative",
                    width: "25rem",
                    height: "22rem",
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(distByCommodity, [ "CommodityType", "AmountIssuedInBirr"])}
                  columns={parseGridColumns(["CommodityType", "AmountIssuedInBirr"])}
                  getRowId={(row) => row.CommodityType}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <CustomPieChart
                  data={distByCommodity}
                  label={"Commodity Type"}
                  fields={["AmountIssuedInBirr", "CommodityType"]}
                  style={{
                    height:'15rem',
                    width: '28rem',
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
              </ChartWrapper>
            </StandardChartWrapper>
            <LongChartWrapper>
              <CustomBarChart
                datas={[distByRegion.map((d) => {
                  return{
                    Region: d.Region,
                    Percentage: Math.round((d.AmountIssuedInBirr*100)/(distByRegion.map((dr) => dr.AmountIssuedInBirr).reduce((partialSum, a) => partialSum + a, 0)))
                  }})]}
                labels={[["Percentage"]]}
                fields={[
                  [
                    "Percentage"
                  ],
                ]}
                styles={[
                  {
                    padding: "1rem",
                    position: "relative",
                    height: "25.8rem",
                    background: "white",
                  },
                ]}
                selectFilterConfig={[QR_COST_SELECT_CONFIG,ENVIRONMENT_SELECT_CONFIG,]}
                defaultFilterParams={loadFilterToDefaultParams(
                  {...QR_REGION_DEFAULT_PARAM},
                  { hasDateRangeFilter: true }
                )}
                datePicker={{
                  date_range: true,
                  start_date_param_name: "From",
                  end_date_param_name: "To",
                  quarter_report: true,
                }}
                chart_options={{
                  title: "Distribution by Region",
                  showExpandAlt: true,
                  option: "Region",
                  indexAxis: "y",
                  max: 100,
                  tooltips: [
                  ],
                  wgInfoId: "wgQuarterReportDistributionByRegion",
                }}
                chartTypes={["bar-chart"]}
                changeLoading={distByRegionLoading}
                parameter={distByRegionParams}
                setParameter={setDistByRegionParams}
              />
            </LongChartWrapper>

            <TableWrapper>
              <CustomBarChart
                datas={[distByHealth]}
                labels={[["Health Center", "Hospital", "Woreda", "Zone", "Region", "Other"]]}
                fields={[
                  [
                    "HealthCenter", "Hospital", "Woreda", "Zone", "Region", "Other"
                  ],
                ]}
                styles={[
                  {
                    padding: "1rem",
                    position: "relative",
                    height: "18rem",
                    background: "white",
                  },
                ]}
                selectFilterConfig={[QR_COST_SELECT_CONFIG, QR_CHART_TYPE_SELECT_CONFIG,]}
                defaultFilterParams={loadFilterToDefaultParams(
                  {...QR_HEALTH_DEFAULT_PARAM},
                  { hasDateRangeFilter: true }
                )}
                datePicker={{
                  date_range: true,
                  start_date_param_name: "From",
                  end_date_param_name: "To",
                  quarter_report: true,
                }}
                chart_options={{
                  title: "Distribution to Health Facilities",
                  showExpandAlt: true,
                  option: "Environment",
                  tooltips: [
                  ],
                  wgInfoId: "wg-quarter-report-distribution-by-health-facilities",
                }}
                chartTypes={["bar-chart"]}
                changeLoading={distByHealthLoading}
                parameter={distByHealthParams}
                setParameter={setDistByHealthParams}
              />
             </TableWrapper>

            <TableWrapper>
              <CustomBarChart
                datas={[distByStockCommodity]}
                labels={[["Chemical And Reagents", "Medical Equipments", "Medical Supplies", "Pharmaceuticals"]]}
                fields={[
                  [
                    "ChemicalsandReagents", "MedicalEquipments", "MedicalSupplies", "Pharmaceuticals"
                  ],
                ]}
                styles={[
                  {
                    padding: "1rem",
                    position: "relative",
                    height: "18rem",
                    background: "white",
                  },
                ]}
                defaultFilterParams={loadFilterToDefaultParams(
                  {...QR_STOCK_COMMODITY_DEFAULT_PARAM},
                  { hasDateFilter: true }
                )}
                datePicker={{
                  date_range: false,
                  start_date_param_name: "TransactionDate",
                }}
                chart_options={{
                  title: "Stock on hand by Commodity type",
                  showExpandAlt: true,
                  option: "Environment",
                  tooltips: [
                  ],
                  wgInfoId: "wg-quarter-report-soh-amount-by-commodity-type",
                }}
                chartTypes={["bar-chart"]}
                changeLoading={distByStockCommodityLoading}
                parameter={distByStockCommodityParams}
                setParameter={setDistByStockCommodityParams}
              />
            </TableWrapper>

            <TableWrapper>
              <CustomBarChart
                datas={[distByProgram.filter((df)=>df.Program !== 'SHI').map((d) => {
                  return{
                    Program: d.Program || "",
                    Percentage: Math.floor((d.AmountIssuedInBirr*100)/(distByProgram.slice(0, 5).filter((df)=>df.Program !== 'SHI').map((dr) => dr.AmountIssuedInBirr).reduce((partialSum, a) => partialSum + a, 0)))
                  }}
                  ).slice(0, 5)]}
                labels={[["Percentage"]]}
                fields={[
                  [
                    "Percentage"
                  ],
                ]}
                styles={[
                  {
                    padding: "1rem",
                    position: "relative",
                    height: "26rem",
                    background: "white",
                  },
                ]}
                selectFilterConfig={[QR_COST_SELECT_CONFIG,ENVIRONMENT_SELECT_CONFIG,]}
                defaultFilterParams={loadFilterToDefaultParams(
                  {...QR_STOCK_COMMODITY_DEFAULT_PARAM},
                  { hasDateRangeFilter: true }
                )}
                datePicker={{
                  date_range: true,
                  start_date_param_name: "From",
                  end_date_param_name: "To",
                  quarter_report: true,
                }}
                chart_options={{
                  title: "Distribution by Program Type",
                  showExpandAlt: true,
                  option: "Program",
                  tooltips: [
                  ],
                  max: 100,
                  wgInfoId: "wg-quarter-report-distribution-by-program",
                }}
                chartTypes={["bar-chart"]}
                changeLoading={distByProgramLoading}
                parameter={distByProgramParams}
                setParameter={setDistByProgramParams}
              />
            </TableWrapper>

            <LongChartWrapper>
              <CustomBarChart
                datas={[nearExpiryAmount]}
                labels={[["Pharmaceuticals", "Medical Supplies", "Medical Equipments", "Chemical And Reagent"]]}
                fields={[
                  [
                    "Pharmaceuticals", "MedicalSupplies", "MedicalEquipments", "ChemicalAndReagent"
                  ],
                ]}
                styles={[
                  {
                    padding: "1rem",
                    position: "relative",
                    height: "23rem",
                    background: "white",
                  },
                ]}
                selectFilterConfig={[QR_TYPE_AMOUNT_SELECT_CONFIG,]}
                defaultFilterParams={loadFilterToDefaultParams(
                  {...QR_NEAR_EXPIRY_AMOUNT_DEFAULT_PARAM},
                  { hasDateFilter: true }
                )}
                datePicker={{
                  date_range: false,
                  start_date_param_name: "From",
                  end_date_param_name: "To",
                }}
                chart_options={{
                  title: "Near Expiry Amount",
                  showExpandAlt: true,
                  option: "Environment",
                  tooltips: [
                  ],
                  wgInfoId: "wg-quarter-report-near-expiry-commodity-12",
                }}
                chartTypes={["bar-chart"]}
                changeLoading={nearExpiryAmountLoading}
                parameter={nearExpiryAmountParams}
                setParameter={setNearExpiryAmountParams}
              />
            </LongChartWrapper>

            <StandardChartWrapper>
              <ChartWrapper 
                title="Near Expiry" 
                wgInfoId="wg-quarter-report-near-expiry-commodity-12"
                showYearFilter={false}
                selectFilterConfig={[ENVIRONMENT_SELECT_CONFIG, QR_TYPE_SELECT_CONFIG]}
                defaultFilterParams={loadFilterToDefaultParams(
                  {...QR_NEAR_EXPIRY_DEFAULT_PARAM},
                  { hasDateFilter: true }
                )}
                datePicker={{
                  date_range: false,
                  start_date_param_name: "From",
                  end_date_param_name: "To",
                }}
                isLoading={distByNearExpiryLoading}
                parameter={distByNearExpiryParams}
                setParameter={setDistByNearExpiryParams}
              >
                <CustomPieChart
                  data={distByNearExpiry}
                  label={["Commodity Type"]}
                  fields={[
                    "SumInBirr", "CommodityType"
                  ]}
                  style={{
                    position: "relative",
                    height: "23rem",
                    width: "25rem",
                    background: "White",
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
                <DataGrid
                  style={{
                    padding: "1rem",
                  }}
                  initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                  autoHeight
                  rows={parseGridRows(distByCommodity, [ "CommodityType", "AmountIssuedInBirr"])}
                  columns={parseGridColumns(["CommodityType", "AmountIssuedInBirr"])}
                  getRowId={(row) => row.CommodityType}
                  rowHeight={30}
                  pageSize={10}
                  rowsPerPageOptions={[5, 10, 25]}
                  pagination
                  animateRows={true}
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                />
                <CustomPieChart
                  data={distByCommodity}
                  label={"Commodity Type"}
                  fields={["AmountIssuedInBirr", "CommodityType"]}
                  style={{
                    height:'15rem',
                    width: '28rem',
                    padding: "0rem 1rem 1rem 1rem",
                  }}
                />
              </ChartWrapper>
            </StandardChartWrapper>

            <TableWrapper>
              <CustomBarChart
                datas={[expiredAmount]}
                labels={[["Medical Supplies", "Medical Equipments", "Chemical And Reagent", "Pharmaceuticals"]]}
                fields={[
                  [
                    "MedicalSupplies", "MedicalEquipments", "ChemicalAndReagent", "Pharmaceuticals"
                  ],
                ]}
                styles={[
                  {
                    padding: "1rem",
                    position: "relative",
                    height: "18rem",
                    background: "white",
                  },
                ]}
                defaultFilterParams={loadFilterToDefaultParams(
                  {...QR_EXPIRED_AMOUNT_DEFAULT_PARAM},
                  { hasDateRangeFilter: true }
                )}
                datePicker={{
                  date_range: true,
                  start_date_param_name: "From",
                  end_date_param_name: "To",
                  quarter_report: true,
                }}
                chart_options={{
                  title: "Expired Products",
                  showExpandAlt: true,
                  option: "Environment",
                  tooltips: [
                  ],
                  wgInfoId: "wg-quarter-report-expired-amount",
                }}
                chartTypes={["bar-chart"]}
                changeLoading={expiredAmountLoading}
                parameter={expiredAmountParams}
                setParameter={setExpiredAmountParams}
              />
            </TableWrapper>

            <TableWrapper>
              <CustomDataGrid
                component_config={{
                  title: "Distribution by Commodity Type",
                  data_source_api: QR_DISTRIBUTION_BY_COMMODITY_API,
                  data_property: "Data",
                  columns: QR_DISTRIBUTION_BY_COMMODITY_COLUMN,
                  isSOH: false,
                  wgInfoId: 'wg-quarter-distribution-commodity-grid',
                  use_default_filter_params: false,
                  select_filter_config: [
                    COST_SELECT_CONFIG,
                  ],
                  defaultFilterParams: loadFilterToDefaultParams(
                    {...MODE_CODE_DEFAULT_PARAM},
                    { hasDateRangeFilter: true }
                  ),
                  date_picker: {
                      date_range: true,
                      start_date_param_name: "From",
                      end_date_param_name: "To",
                    },
                }}
              />
            </TableWrapper>
            <TableWrapper>
              <CustomDataGrid
                component_config={{
                  title: "Distribution by Ownership Type",
                  data_source_api: QR_DISTRIBUTION_BY_OWNERSHIP_API,
                  data_property: "Data",
                  columns: QR_DISTRIBUTION_BY_OWNERSHIP_COLUMN,
                  isSOH: false,
                  wgInfoId: 'wg-quarter-report-distribution-ownership-type',
                  use_default_filter_params: false,
                  select_filter_config: [
                    COST_SELECT_CONFIG,
                  ],
                  defaultFilterParams: loadFilterToDefaultParams(
                    {...MODE_CODE_DEFAULT_PARAM},
                    { hasDateRangeFilter: true }
                  ),
                  date_picker: {
                      date_range: true,
                      start_date_param_name: "From",
                      end_date_param_name: "To",
                      quarter_report: true,
                    },
                }}
              />
            </TableWrapper>
            <TableWrapper>
              <CustomDataGrid
                component_config={{
                  title: "Procured Local Manufacturer",
                  data_source_api: QR_PROCURED_LOCAL_API,
                  data_property: "Data",
                  columns: QR_PROCURED_LOCAL_COLUMN,
                  isSOH: false,
                  wgInfoId: 'wg-quarter-report-procured-local-manufacturer',
                  use_default_filter_params: false,
                  select_filter_config: [
                    ENVIRONMENT_SELECT_CONFIG,
                  ],
                  defaultFilterParams: loadFilterToDefaultParams(
                    {...MODE_CODE_DEFAULT_PARAM},
                    { hasDateRangeFilter: true }
                  ),
                  date_picker: {
                      date_range: true,
                      start_date_param_name: "From",
                      end_date_param_name: "To",
                      quarter_report: true,
                    },
                }}
              />
            </TableWrapper>
          </GridContainer>
        </div>
      </div>
    </div>
    <Footer />
  </Wrapper>)
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1.3rem 1.5rem;
`;

const TableWrapper = styled.div`
    display: flex;
    grid-column: span 3;
`;

const StandardChartWrapper = styled.div`
    display: flex;
    grid-column: span 1;
`;

const LongChartWrapper = styled.div`
    display: flex;
    grid-column: span 2;
`;

const Wrapper = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  .container-box {
    background: #fff;
    width: 100%;
    padding: 25px, 0px;
    margin: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .card-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 0.7rem;
  }
  .container-context {
    display: flex;
    flex-direction: row;
    padding: 0 1rem 1rem 1rem;
    width: 100%;
    flex: 1;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    padding-left: 3rem;
    flex-wrap: wrap;
    align-items: center;
  }
  .button {
    border: 1px solid transparent;
    height: 2rem;
    width: 6rem;
    font-size: 14px;
    background-color: cadetblue;
    color: white;
    margin-right: 1rem;
    border-radius: 4px;
  }
  .flex-row {
    display: flex;
    width: 100%;
    flex-grow: 1;
  }

  .flex-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
  }
  .flex-body {
    padding-right: 1rem;
  }
  .grid {
    margin-bottom: 1rem;
  }
  .group-chart {
    background-color: white;
    position: relative;
    margin-bottom: 0.1rem;
    border: 1px solid lightgrey;
    padding: 10px 5px 10px 5px;
  }
`;