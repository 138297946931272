
import React, { useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import Footer from "../../shared/Footer";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import { ERP_Distribution_API } from "../../shared/Shared Utils/AppData";
import styled from "styled-components";
import { getGShortDate } from "../../../Utils/NumberUtils";
import { Box } from "@mui/material";
import DistributionReport from "./DistributionReport";
import UsableStockReport from "./UsableStockReport";

const TransactionReport = () => {
  return (
    <Wrapper>
      {" "}
      <NavBar title={`ERP Transaction Report`} />
      <DistributionReport />
      <UsableStockReport />
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
`;

export default TransactionReport;