import React, { Component, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import EPSALOGO from "../../assets/EPSSLogo.jpg";

export default function Footer({ }) {
  const history = useNavigate();

  return (
    <Wrapper>
      <div 
          className="flex-row footer"
          style={{ alignItems: 'center' }}>
        <div>
          <img
            src={EPSALOGO}
            alt="loading"
            style={{
              height: "50px",
            }}
            className="pfsa-logo"
          />
        </div>
        <div>
          <div className="flex-column" style={{ paddingTop: '0.5rem'}}>
            <div><h6>Fanos Dashboard</h6></div>
            <div><h6>Ethiopian Pharmaceutical Supply Service</h6></div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
`;
