import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { RiSubtractFill } from "react-icons/ri";
import NavBar from "../shared/NavBar";
import Footer from "../shared/NavBar";
import styled from "styled-components";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { DataGrid } from "@mui/x-data-grid";

export default function Formulas(){
    return (<Wrapper>
      <div>
        {" "}
        <NavBar title={`Fanos Formulas`} />
      </div>
      <Container>  
        <Tabs style={{ paddingTop: 10 }}>
            <TabList>
                <Tab>AMC</Tab>
                <Tab>DOS</Tab>
                <Tab>ISSUE</Tab>
                <Tab>MOS</Tab>
                <Tab>RECEIPT</Tab>
                <Tab>SOH</Tab>
            </TabList>
            <TabPanel>
                <AMC />
            </TabPanel>
            <TabPanel>
                <DOS />
            </TabPanel>
            <TabPanel>
                <Issue />
            </TabPanel>
            <TabPanel>
                <MOS />
            </TabPanel>
            <TabPanel>
                <Receipt />
            </TabPanel>
            <TabPanel>
                <SOH />
            </TabPanel>
        </Tabs>
      </Container>
      <Footer />
    </Wrapper>
    )
}


const AMC = () => {
    return (<CardWrapper>
        <FormulaCard>
            <Title>
                National AMC
            </Title>
            <FormulaWrapper>
                <Variable>
                    National Issues
                </Variable>
                <FaTimes style={{color: "#7ba4cc", margin: "0 5px" }} />
                <Variable>
                    30 days in a month
                </Variable>
            </FormulaWrapper>
            <DivisionSymbol />
            <FormulaWrapper>
                <Variable>
                    365 days
                </Variable>
                <RiSubtractFill style={{color: "#7ba4cc", margin: "0 5px" }} />
                <Variable>
                    Weighted Average of Days Out of Stock
                </Variable>
            </FormulaWrapper>
        </FormulaCard>
        <FormulaCard>
            <Title>
                Center AMC
            </Title>
            <FormulaWrapper>
                <Variable>
                    Center Issues
                </Variable>
                <FaTimes style={{color: "#7ba4cc", margin: "0 5px" }} />
                <Variable>
                    30 days in a month
                </Variable>
            </FormulaWrapper>
            <DivisionSymbol />
            <FormulaWrapper>
                <Variable>
                    365 days
                </Variable>
                <RiSubtractFill style={{color: "#7ba4cc", margin: "0 5px" }} />
                <Variable>
                    # Center Days Out of Stock
                </Variable>
            </FormulaWrapper>
        </FormulaCard>
        <FormulaCard>
            <Title>
                Hub AMC
            </Title>
            <FormulaWrapper>
                <Variable>
                    Hub Issues
                </Variable>
                <FaTimes style={{color: "#7ba4cc", margin: "0 5px" }} />
                <Variable>
                    30 days in a month
                </Variable>
            </FormulaWrapper>
            <DivisionSymbol />
            <FormulaWrapper>
                <Variable>
                    365 days
                </Variable>
                <RiSubtractFill style={{color: "#7ba4cc", margin: "0 5px" }} />
                <Variable>
                    # Hub Days Out of Stock
                </Variable>
            </FormulaWrapper>
        </FormulaCard>
    </CardWrapper>)
}

const DOS = () => {
    return (<Box>
        <CardWrapper>
            <FormulaCard>
                <Title>
                    DOS Formula
                </Title>
                <FormulaWrapper>
                    <Variable>
                        # of Days when SOH = 0
                    </Variable>
                </FormulaWrapper>
            </FormulaCard>
        </CardWrapper>
    </Box>)
}

const MOS = () => {
    return (<Box>
        <CardWrapper>
            <FormulaCard>
                <Title>
                    National MOS Formula
                </Title>
                <FormulaWrapper>
                    <Variable>
                        National Stock on Hand
                    </Variable>
                </FormulaWrapper>
                <DivisionSymbol />
                <FormulaWrapper>
                    <Variable>
                        National Average Monthly Consumption
                    </Variable>
                </FormulaWrapper>
            </FormulaCard>
            <FormulaCard>
                <Title>
                    Center MOS Formula
                </Title>
                <FormulaWrapper>
                    <Variable>
                        Center Stock on Hand
                    </Variable>
                </FormulaWrapper>
                <DivisionSymbol />
                <FormulaWrapper>
                    <Variable>
                        Center Average Monthly Consumption
                    </Variable>
                </FormulaWrapper>
            </FormulaCard>
            <FormulaCard>
                <Title>
                    Hub MOS Formula
                </Title>
                <FormulaWrapper>
                    <Variable>
                        Hub Stock on Hand
                    </Variable>
                </FormulaWrapper>
                <DivisionSymbol />
                <FormulaWrapper>
                    <Variable>
                        Hub Average Monthly Consumption
                    </Variable>
                </FormulaWrapper>
            </FormulaCard>
        </CardWrapper>
        <CardWrapper>
            <FormulaCard>
                <FormulaWrapper>
                    <Variable>
                        Average Days Out of Stock in Last 12 Months
                    </Variable>
                </FormulaWrapper>
                <DivisionSymbol />
                <FormulaWrapper>
                    <Variable>
                        Total Receipts in Last 12 Months
                    </Variable>
                </FormulaWrapper>
            </FormulaCard>
            <FormulaCard>
                <FormulaWrapper>
                    <Variable>
                        Average Days Out of Stock in Last 12 Months
                    </Variable>
                </FormulaWrapper>
                <DivisionSymbol />
                <FormulaWrapper>
                    <Variable>
                        Total Receipts in Last 12 Months
                    </Variable>
                </FormulaWrapper>
            </FormulaCard>
        </CardWrapper>
    </Box>)
}

const SOH = () => {
    return (<Box>
        <CardWrapper>
            <FormulaCard>
                <Title>
                    SOH
                </Title>
                <FormulaWrapper>
                    <Variable>
                        Received – Issued – Inventory Issued - Damaged - Reserved - Suspended - Expired
                    </Variable>
                </FormulaWrapper>
            </FormulaCard>
            <FormulaCard>
                <Title>
                    National
                </Title>
                <FormulaWrapper>
                    <Variable>
                        Stock on Hand at all Sites = National Stock on Hand
                    </Variable>
                </FormulaWrapper>
            </FormulaCard>
            <FormulaCard>
                <Title>
                    Center
                </Title>
                <FormulaWrapper>
                    <Variable>
                        All Receipts - All Issues - [Expired + Damaged + Reserved + Suspended Commodities] = Center Stock on Hand
                    </Variable>
                </FormulaWrapper>
            </FormulaCard>
        </CardWrapper>
        <CardWrapper>
            <FormulaCard>
                <Title>
                    Center
                </Title>
                <FormulaWrapper>
                    <Variable>
                        All Receipts - All Issues - [Expired + Damaged + Reserved + Suspended Commodities] = Hub Stock on Hand
                    </Variable>
                </FormulaWrapper>
            </FormulaCard>
        </CardWrapper>
    </Box>)
}

const IssueColumns = [
  { field: "Issue", renderHeader: (params) => <strong>{"Issue"}</strong>, flex: 2 },
  { field: "National", renderHeader: (params) => <strong>{"National = "}</strong>, flex: 1 },
  { field: "Center", renderHeader: (params) => <strong>{"Center = "}</strong>, flex: 1 },
  { field: "Hub", renderHeader: (params) => <strong>{"Hub = "}</strong>, flex: 1 },
];

const IssueData = [
  { Issue: "Hub to Hub Transfer", National: "+", Center: "+", Hub: "+" },
  { Issue: "Account Transfer - Across a Mode", National: "", Center: "+", Hub: "+" },
  { Issue: "Account Transfer - Within a Mode", National: "", Center: "", Hub: "" },
  { Issue: "Store to Store Transfer", National: "NA", Center: "NA", Hub: "NA" },
  { Issue: "Error Correction Transfer", National: "+/-", Center: "+/-", Hub: "+/-" },
  { Issue: "Stock Return Memo", National: "-", Center: "-", Hub: "-" },
  { Issue: "Transfer PFSA to FMHACA", National: "+", Center: "+", Hub: "+" },
  { Issue: "Error Correction Transfer", National: "+", Center: "+", Hub: "" },
  { Issue: "Standard Issue - Hub to Facility", National: "-", Center: "NA", Hub: "+" },
  { Issue: "Standard Issue - Center to Facility", National: "+", Center: "+", Hub: "NA" },
  { Issue: "Standard Issue Center to Hub", National: "+", Center: "+", Hub: "NA" },
];

const Issue = () => {
    return(<Box pb={4}>
        <DataGrid
            style={{
                background: "white",
                fontFamily:
                '"open sans","Helvetica Neue",Helvetica,Arial,sans-serif',
                opacity: "1",
            }}
            initialState={{pagination:{paginationModel:{pageSize: 10, page:0}}}}
            autoHeight
            disableRowSelectionOnClick
            getRowHeight={() => 'auto'}
            rows={IssueData}
            columns={IssueColumns}
            experimentalFeatures={{ columnGrouping: true }}
            density="standard"
            getRowId={(row) => row.Issue}
            rowHeight={40}
            pagination
            sx={{
                '& .MuiDataGrid-row': {
                minHeight: '45px !important',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                whiteSpace: 'pre-wrap',
                textOverflow: 'unset',
                overflow: 'unset',
                maxHeight: 'fit-content !important',
                },
                '& .MuiDataGrid-columnHeader': {
                maxHeight: 'fit-content !important',
                lineHeight: '26px !important',
                },
                ".highlight": {
                bgcolor: "#f0eded"
                }
                }}
            pageSizeOptions={[5, 10, 25]}
            componentsProps={{
                toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 600,  },
                },
            }}
            disableDensitySelector
            />
    </Box>
    )
}

const ReceiptColumns = [
  { field: "Receipt", renderHeader: (params) => <strong>{"Receipt"}</strong>, flex: 2 },
  { field: "National", renderHeader: (params) => <strong>{"National GRNF = "}</strong>, flex: 1 },
  { field: "Center", renderHeader: (params) => <strong>{"Center GRNF = "}</strong>, flex: 1 },
  { field: "Hub", renderHeader: (params) => <strong>{"Hub GRNF = "}</strong>, flex: 1 },
];

const ReceiptData = [
  { Receipt: "Local Purchase", National: "+", Center: "+", Hub: "+" },
  { Receipt: "Manual Delivery Note", National: "+", Center: "-", Hub: "-" },
  { Receipt: "Error Correction", National: "+/-", Center: "+/-", Hub: "+/-" },
  { Receipt: "Store Transfer", National: "NA", Center: "NA", Hub: "NA" },
  { Receipt: "Account Transfer - Across a Mode", National: "+", Center: "+", Hub: "+" },
  { Receipt: "Account Transfer - Within a Mode", National: "NA", Center: "NA", Hub: "NA" },
  { Receipt: "Beginning Balance", National: "+", Center: "+", Hub: "+" },
  { Receipt: "Stock Return Memo", National: "+", Center: "+", Hub: "+" },
  { Receipt: "Delivery Note", National: "+", Center: "+", Hub: "+" },
  { Receipt: "Standard Receipt", National: "+", Center: "+", Hub: "+" },
];

const Receipt = () => {
    return(<Box pb={4}>
        <DataGrid
            style={{
                background: "white",
                fontFamily:
                '"open sans","Helvetica Neue",Helvetica,Arial,sans-serif',
                opacity: "1",
            }}
            initialState={{pagination:{paginationModel:{pageSize: 10, page:0}}}}
            autoHeight
            disableRowSelectionOnClick
            getRowHeight={() => 'auto'}
            rows={ReceiptData}
            columns={ReceiptColumns}
            experimentalFeatures={{ columnGrouping: true }}
            density="standard"
            getRowId={(row) => row.Receipt}
            rowHeight={40}
            pagination
            sx={{
                '& .MuiDataGrid-row': {
                minHeight: '45px !important',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                whiteSpace: 'pre-wrap',
                textOverflow: 'unset',
                overflow: 'unset',
                maxHeight: 'fit-content !important',
                },
                '& .MuiDataGrid-columnHeader': {
                maxHeight: 'fit-content !important',
                lineHeight: '26px !important',
                },
                ".highlight": {
                bgcolor: "#f0eded"
                }
                }}
            pageSizeOptions={[5, 10, 25]}
            componentsProps={{
                toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 600,  },
                },
            }}
            disableDensitySelector
            />
    </Box>
    )
}

const Wrapper = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const Container = styled(Box)`
  margin: 100px 100px 0px;
`;

const CardWrapper = styled(Box)`
  display: flex;
  flex: 1;
`;

const FormulaCard = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  border-radius: 15px; 
  padding: 16px;
  margin: 10px;
  box-shadow: 0 2px 5px 0 grey;
  elevation: 14;
`;

const Title = styled(Box)`
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const FormulaWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

const Variable = styled(Box)`
  width: 9.5rem;
  border: 2px solid #7ba4cc;
  color: #7ba4cc;
  text-align: center;
  padding: 3px 3px;
  margin: 3px auto;
  border-radius: 3px;
`;

const DivisionSymbol = styled.hr`
  height: 2px !important;
  margin: 0.5rem 0;
`;