import React, {
  useState
} from "react";

import { useNavigate } from "react-router-dom";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import styled from "styled-components";

// custom components

// Icons
import { HiExternalLink, HiOutlineOfficeBuilding } from "react-icons/hi";
import { TbMedicineSyrup } from "react-icons/tb";
import { GiMedicines } from "react-icons/gi";
import { FaExpandAlt } from "react-icons/fa";
import { IsEmpty } from "../Helpers/FormatHelper";
import DynamicDataGrid from "./DynamicDataGrid";
import CustomLoadingIndicator from "../Plugins/CustomLoadingIndicator";
import WidgetInfo from "../Plugins/WidgetInfo";

function CustomCardV3({ widget_config, Icon }) {
  const history = useNavigate();
  const modal_config = widget_config?.modal_config;

  const styleIcon = {
    color: "white",
    padding: "0.5rem",
    backgroundColor: widget_config.accent_color,
    borderRadius: "1.5rem",
    margin: "0.2rem 0.2rem 0.2rem 0",
    transition: "all 0.3s ease-in-out",
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Wrapper 
    className="grow-1">
    
      <div
        style={widget_config.value == null ? { opacity: "0.8", minHeight: "100%" } : { minHeight: "100%"}}
      >
        <div
          className="main-container custom-card-container flex-column"
          style={{ justifyContent: "space-between", border: "1px solid #e6e6e6",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1),0 1px 3px rgba(0,0,0,0.08)"
         }}

        >
          <div className="widget-header-container flex-column">
            <div>
              <div className="flex-row">
                <h6
                  className="widget-header grow-1"
                  style={{ color: "black", fontWeight: "500" }}
                >
                  {widget_config.title + " "}
                  {/* <WidgetInfo widget_config={{
                      title: widget_config.title,
                      page: widget_config.page }}/> */}
                </h6>
                <div>
                  {widget_config.value != null && modal_config && (
                    <div>
                      <FaExpandAlt
                        className="expand-icon-custom-card"
                        onClick={handleOpen}
                      />
                    </div>
                  )}
                </div>
                {IsEmpty(widget_config.value) && <CustomLoadingIndicator />}
              </div>
            </div>
            <div>
              <Modal className="rs-dialog"
                  open={open} onClose={handleClose}>
                <Box className="rs-d-container">
                  <div>
                    <div className="flex-column">
                      <h6>{ modal_config?.title
                          ? modal_config?.title
                          : widget_config.description }</h6>
                      <div>
                          <DynamicDataGrid component_config={ modal_config?.grid_config } />
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
            <div style={{margin: "0.5rem 0"}}>
              <span>
                <Icon size={40} style={styleIcon} />{" "}
                <span style={{ fontSize: "1.8rem", verticalAlign: "top" }}>
                  {widget_config.value}
                </span>
              </span>
            </div>
          </div>
          <div className="flex-column">
            {widget_config.type && (
              <span>
                <span style={{ color: "#475f7b" }}>
                  {widget_config.type === "Facility" && (
                    <HiOutlineOfficeBuilding />
                  )}
                  {widget_config.type === "EPSS" && <TbMedicineSyrup />}
                  {widget_config.type === "Regulatory" && <GiMedicines />}
                </span>{" "}
                {widget_config.type}
              </span>
            )}
            {widget_config.link && (
              <span
                onClick={() => history(widget_config.link)}
                style={{ cursor: "pointer" }}
              >
                <span style={{ color: "#475f7b" }}>
                  <HiExternalLink size={25} />
                </span>
                <span
                  style={{
                    fontSize: "var(--fs-300)",
                    verticalAlign: "middle",
                  }}
                >
                  View more
                </span>
              </span>
            )}
            <span style={{ lineHeight: "1.2rem", minHeight: "2.4rem" }}>
              {widget_config.description}
            </span>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-width: 20rem
`

export default CustomCardV3;
