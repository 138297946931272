import { FaEllipsisH } from "react-icons/fa";
import 'react-circular-progressbar/dist/styles.css';
import "./style.css";
import styled from "styled-components";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import { useEffect } from "react";
import { getHubsLocation } from "../../../Services/ApiManager";
import { useState } from "react";
import { Tooltip } from "@mui/material";

const MapCard = ({title, pinColor}) => {
  const center = [39.1699, 9.1450];
  const [hubLocations, setHubLocations] = useState([]);

  useEffect(() => {
    getHubsLocation().then((res) => {
      setHubLocations(res.data.Data)
    });
  },[]);


  return (
      <Wrapper className="grow-1">
        <div className="map-main-container">
        <Header>
          <Title>
              {title}
          </Title>
        </Header>
        <div style={{}}>
            <ComposableMap 
                projection="geoMercator"
                projectionConfig={{
                    scale: 2500,
                    center,
                }}
            >
            <Geographies geography="/features.json">
                {({ geographies }) =>
                geographies.map((geo) => (
                    <Geography 
                        key={geo.rsmKey} 
                        geography={geo} 
                        style={{
                            default: {
                                fill: 'none',
                                stroke: '#000',
                                strokeWidth: 1,
                                outline: 'none',
                            },
                            hover: {
                                fill: 'none',
                                stroke: '#000',
                                strokeWidth: 1,
                                outline: 'none',
                            },
                            pressed: {
                                fill: 'none',
                                stroke: '#000',
                                strokeWidth: 1,
                                outline: 'none',
                            },
                        }} 
                    />
                ))
                }
            </Geographies>
            {hubLocations && hubLocations.map((circle, index) => (
            <Tooltip title={`${hubLocations[index].Environment} : ${circle.Longitude} Longitude, ${circle.Latitude} Latitude`}>
                <Marker 
                    key={index}
                    coordinates={[circle.Longitude, circle.Latitude]}
                >
                    <g transform="translate(-12, -24)">
                        <path
                            fill={pinColor}
                            d="M12 0C5.373 0 0 5.373 0 12c0 8.625 12 24 12 24s12-15.375 12-24c0-6.627-5.373-12-12-12z"
                        />
                        <text
                            x="12"
                            y="12"
                            fontFamily="Arial"
                            fontSize="12"
                            textAnchor="middle"
                            fill="#FFFFFF"
                        >
                            {index + 1}
                        </text>
                    </g>
                </Marker>
            </Tooltip>))}
            </ComposableMap>
        </div>
        </div>
      </Wrapper>
    );
}

export default MapCard;

const Wrapper = styled.div`
    .map-main-container {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 8px;
        padding: 0.5rem 1rem;
        min-height: 23rem;
        boxShadow: "0 4px 6px rgba(0,0,0,0.1),0 1px 3px rgba(0,0,0,0.08)"
        margin: "0.5rem"
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: "black";
    border-radius: 8px 8px 0 0;
    gap: 40px;
`;

const Title = styled.div`
    font-size: 1.1rem;
    font-weight: 500;
`;
const DateSelected = styled.div`
    font-size: 1rem;
    color: #545E66;
`;
