import axios from 'axios';


const { REACT_APP_API_ENDPOINT } = process.env;

// Create an Axios instance
const cachedApi = axios.create({
  baseURL: REACT_APP_API_ENDPOINT
});

// Function to generate a unique cache key based on URL and parameters
const generateCacheKey = (url, params) => {
  // Serialize parameters into a query string or a JSON string
  const queryString = params ? `?${new URLSearchParams(params).toString()}` : '';
  return `${url}${queryString}`;  // Combine the URL and query parameters as a cache key
};

// Cache function to store responses
const setCache = (url, params, response) => {
  try {
    const cacheKey = generateCacheKey(url, params);
    const cachedData = {
      data: response,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));  // Cache data in localStorage
  } catch (error) {
    console.log("error setting cache");
    console.log(error);
  }
};

// Cache function to retrieve cached responses
const getCache = (url, params) => {
  console.log(url);
  const cacheKey = generateCacheKey(url, params);
  const cachedData = localStorage.getItem(cacheKey);

  if (cachedData && cachedData?.data?.Data?.length > 0) {
    const parsedData = JSON.parse(cachedData);
    const currentTime = new Date().getTime();

    // Cache expiration logic (optional)
    const cacheDuration = 6 * 60 * 60 * 1000;  // Cache duration in milliseconds (e.g., 5 minutes)

    if (currentTime - parsedData.timestamp < cacheDuration) {
      return parsedData.data;  // Return cached data if it's still valid
    } else {
      localStorage.removeItem(cacheKey);  // Cache expired, remove it
    }
  }
  return null;  // Return null if no valid cache is found
};

// Request interceptor to check for cached responses before making a request
cachedApi.interceptors.request.use((config) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let token = userInfo?.Key;
  config.headers["Authorization"] = "Basic " + token;
  const cachedData = getCache(config.url, config.params);  // Pass parameters for cache lookup
  console.log(cachedData);
  if (cachedData && cachedData?.data?.Data?.length > 0) {
    console.log(cachedData);
    // If data is in cache, return it as a mock response
    // return Promise.reject({
    //   response: { data: cachedData },
    // });
  }
  return config;
}, (error) => {
  console.log(error);
  return Promise.reject(error);
});

// Response interceptor to cache the response
cachedApi.interceptors.response.use((response) => {
  if(response?.data?.Data?.length > 0)
    setCache(response?.config?.url, response?.config?.params, response?.data);  // Cache the response with URL and params
  return response;
}, (error) => {
  console.log(error);
  return Promise.reject(error);
});

export default cachedApi;
