import React, { useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import Footer from "../../shared/Footer";
import styled from "styled-components";

import {
  getQRDistributionByCommodityType,
  getQRDistributionByRegionType,
  getQRDistributionByProgramType,
  getQRNearExpiry,
  getQRDistributionByHealthFacility,
  getQRDistributionByStockCommodityType,
  getQRNearExpiryAmount,
  getQRExpiredAmount,
} from "../../../Services/chartRequests";
import {
  getEnvironemntList,
  getQuarterReportSummary,
} from "../../../Services/ApiManager";
import {
  QR_DISTRIBUTION_BY_COMMODITY_API,
  QR_DISTRIBUTION_BY_OWNERSHIP_API,
  QR_DISTRIBUTION_BY_PROGRAM,
  QR_DISTRIBUTION_BY_PROGRAM_APi,
  QR_EXPIRED_API,
  QR_LOCAL_API,
  QR_NEAR_EXPIRY_API,
  QR_PROCURED_LOCAL_API,
  QR_RAW_DISTRIBUTION_API,
  QR_SOH_API,
} from "../../shared/Shared Utils/AppData.js";

import CustomPieChart from "../../shared/Charts/CustomPieChart";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "../Program/style.css";
import CustomCard from "../../shared/Shared Utils/CustomCards";
import {
  COST_SELECT_CONFIG,
  ENVIRONMENT_SELECT_CONFIG,
  HUB_VITAL_ENVIRONMENT_SELECT_CONFIG,
  MODE_CODE_DEFAULT_PARAM,
  MODE_ENVIRONMENT_DEFAULT_PARAM,
  QR_CHART_TYPE_SELECT_CONFIG,
  QR_COMMODITY_DEFAULT_PARAM,
  QR_COST_SELECT_CONFIG,
  QR_EXPIRED_AMOUNT_DEFAULT_PARAM,
  QR_HEALTH_DEFAULT_PARAM,
  QR_NEAR_EXPIRY_AMOUNT_DEFAULT_PARAM,
  QR_NEAR_EXPIRY_DEFAULT_PARAM,
  QR_REGION_DEFAULT_PARAM,
  QR_REPORT_DEFAULT_PARAM,
  QR_STOCK_COMMODITY_DEFAULT_PARAM,
  QR_TYPE_AMOUNT_SELECT_CONFIG,
  QR_TYPE_SELECT_CONFIG,
  TYPE_SELECT_CONFIG,
} from "../../shared/Shared Utils/DataTableConstants";
import {
  MdMedicalServices,
} from "react-icons/md";
import { HUB } from "../../shared/Shared Utils/InputConstants";
import { parseGridColumns, parseGridRows, } from "../../../Utils/ChartConverter";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ChartWrapper from "../../shared/Charts/ChartWrapper";
import sohPic from "../../../assets/cardIcons/soh.svg";
import stackPic from "../../../assets/cardIcons/stack.svg";
import gitPic from "../../../assets/cardIcons/git.svg";
import expPic from "../../../assets/cardIcons/expired.svg";
import { QR_DISTRIBUTION_BY_COMMODITY_COLUMN, QR_DISTRIBUTION_BY_OWNERSHIP_COLUMN, QR_DISTRIBUTION_BY_PROGRAM_COLUMN, QR_EXPIRED_COLUMN, QR_LOCAL_COLUMN, QR_NEAR_EXPIRY_COLUMN, QR_RAW_DISTRIBUTION, QR_RAW_DISTRIBUTION_COLUMN, QR_SOH_COLUMN } from "./QuarterReportTableColumn";
import moment from "moment";
import CustomBarChart from "../../shared/Charts/CustomBarChartV2";
import CustomDatePicker from "../../shared/Shared Utils/CustomDatePicker";
import { FormControlLabel, Switch } from "@mui/material";

export default function RawData() {
  const [modeCode, setModeCode] = useState("HPR");
  const [checked, setChecked] = useState(true);

  function loadFilterToDefaultParams(filterParams, dateFilterFlag) {
    if (dateFilterFlag.hasDateFilter) {
      let newDate = new Date();
      let day = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let currentDate = `${month}/${day}/${year}`;
      filterParams["TransactionDate"] = currentDate;
    }
    if (dateFilterFlag.hasDateFilterTo) {
      let newDate = new Date();
      let day = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let currentDate = `${month}/${day}/${year}`;
      filterParams["To"] = currentDate;
    }
    if (dateFilterFlag.hasDateRangeFilter) {
      const start = moment().subtract(3, 'months').format("MM/DD/YYYY");
      const end = moment().format("MM/DD/YYYY");
      filterParams["From"] = start;
      filterParams["To"] = end;
    }
    filterParams["ModeCode"] = modeCode;
    return filterParams;
  }


  return (
  <Wrapper>
    <div>
      {" "}
      <NavBar title={`Fanos Quarter Report Dashboard`} />
    </div>
    <div className="mt-3" style={{marginRight: "5vw", marginLeft: "5vw"}}>
      <div>
        <div className="d-flex" style={{ padding: "10px 10px", alignItems: "center", justifyContent: "space-between"}}>
          <h2 className="grid-title">
            Quarter Report Raw
          </h2>
          <FormControlLabel control={
              <Switch
                defaultChecked={true} 
                onChange={(e)=> {
                  setChecked(e.target.checked);
                  e.target.checked? setModeCode('HPR'): setModeCode('RDF');
                }} />} 
              label={checked?"HPR": "RDF"} />
        </div>
        <hr/>

        <GridContainer>
          <TableWrapper>
            <CustomDataGrid
              component_config={{
                title: "Distribution by Commodity",
                data_source_api: QR_RAW_DISTRIBUTION_API,
                data_property: "Data",
                columns: QR_RAW_DISTRIBUTION_COLUMN,
                isSOH: false,
                width: "90vw",
                wgInfoId: 'wg-quarter-report-raw-distribution',
                use_default_filter_params: false,
                select_filter_config: [
                  HUB_VITAL_ENVIRONMENT_SELECT_CONFIG,
                ],
                defaultFilterParams: loadFilterToDefaultParams(
                  {...MODE_ENVIRONMENT_DEFAULT_PARAM},
                  { hasDateRangeFilter: true }
                ),
                date_picker: {
                    date_range: true,
                    start_date_param_name: "From",
                    end_date_param_name: "To",
                  },
              }}
            />
          </TableWrapper>
          <TableWrapper>
            <CustomDataGrid
              component_config={{
                title: "Distribution by Program",
                data_source_api: QR_DISTRIBUTION_BY_PROGRAM_APi,
                data_property: "Data",
                columns: QR_DISTRIBUTION_BY_PROGRAM_COLUMN,
                isSOH: false,
                width: "90vw",
                wgInfoId: 'wg-quarter-report-raw-distribution-by-program',
                use_default_filter_params: false,
                select_filter_config: [
                  HUB_VITAL_ENVIRONMENT_SELECT_CONFIG,
                ],
                defaultFilterParams: loadFilterToDefaultParams(
                  {...MODE_ENVIRONMENT_DEFAULT_PARAM},
                  { hasDateRangeFilter: true }
                ),
                date_picker: {
                    date_range: true,
                    start_date_param_name: "From",
                    end_date_param_name: "To",
                  },
              }}
            />
          </TableWrapper>
          <TableWrapper>
            <CustomDataGrid
              component_config={{
                title: "SOH",
                data_source_api: QR_SOH_API,
                data_property: "Data",
                columns: QR_SOH_COLUMN,
                isSOH: false,
                width: "90vw",
                wgInfoId: 'wg-quarter-report-soh-raw-data',
                use_default_filter_params: false,
                select_filter_config: [
                  HUB_VITAL_ENVIRONMENT_SELECT_CONFIG,
                ],
                defaultFilterParams: loadFilterToDefaultParams(
                  {...MODE_ENVIRONMENT_DEFAULT_PARAM},
                  { hasDateFilter: true }
                ),
                date_picker: {
                    date_range: false,
                    start_date_param_name: "TransactionDate",
                  },
              }}
            />
            </TableWrapper>
            <TableWrapper>
            <CustomDataGrid
              component_config={{
                title: "Local Manufacturer",
                data_source_api: QR_LOCAL_API,
                data_property: "Data",
                columns: QR_LOCAL_COLUMN,
                isSOH: false,
                width: "90vw",
                wgInfoId: 'wg-quarter-report-local-manufacturer',
                use_default_filter_params: false,
                select_filter_config: [
                  HUB_VITAL_ENVIRONMENT_SELECT_CONFIG,
                ],
                defaultFilterParams: loadFilterToDefaultParams(
                  {...MODE_ENVIRONMENT_DEFAULT_PARAM},
                  { hasDateRangeFilter: true }
                ),
                date_picker: {
                    date_range: true,
                    start_date_param_name: "From",
                    end_date_param_name: "To",
                  },
              }}
            />
          </TableWrapper>
            
          <TableWrapper>
            <CustomDataGrid
              component_config={{
                title: "Near Expiry",
                data_source_api: QR_NEAR_EXPIRY_API,
                data_property: "Data",
                columns: QR_NEAR_EXPIRY_COLUMN,
                isSOH: false,
                width: "90vw",
                wgInfoId: 'wg-quarter-report-near-expiry-raw-data',
                use_default_filter_params: false,
                select_filter_config: [
                  HUB_VITAL_ENVIRONMENT_SELECT_CONFIG,
                  TYPE_SELECT_CONFIG,
                ],
                defaultFilterParams: loadFilterToDefaultParams(
                  {...MODE_ENVIRONMENT_DEFAULT_PARAM, Type: "6"},
                  { hasDateFilterTo: true }
                ),
                date_picker: {
                    date_range: false,
                    start_date_param_name: "To",
                  },
              }}
            />
          </TableWrapper>
            
          <TableWrapper>
            <CustomDataGrid
              component_config={{
                title: "Expired",
                data_source_api: QR_EXPIRED_API,
                data_property: "Data",
                columns: QR_EXPIRED_COLUMN,
                isSOH: false,
                width: "90vw",
                wgInfoId: 'wg-quarter-report-expired-raw-data',
                use_default_filter_params: false,
                select_filter_config: [
                  HUB_VITAL_ENVIRONMENT_SELECT_CONFIG,
                ],
                defaultFilterParams: loadFilterToDefaultParams(
                  {...MODE_ENVIRONMENT_DEFAULT_PARAM},
                  { hasDateRangeFilter: true }
                ),
                date_picker: {
                    date_range: true,
                    start_date_param_name: "From",
                    end_date_param_name: "To",
                  },
              }}
            />
          </TableWrapper>
        </GridContainer>
      </div>
    </div>
    <Footer />
  </Wrapper>)
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1.3rem 1.5rem;
`;

const TableWrapper = styled.div`
    display: flex;
    grid-column: span 3;
`;

const Wrapper = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  .container-box {
    background: #fff;
    width: 100%;
    padding: 25px, 0px;
    margin: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .card-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 0.7rem;
  }
  .container-context {
    display: flex;
    flex-direction: row;
    padding: 0 1rem 1rem 1rem;
    width: 100%;
    flex: 1;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    padding-left: 3rem;
    flex-wrap: wrap;
    align-items: center;
  }
  .button {
    border: 1px solid transparent;
    height: 2rem;
    width: 6rem;
    font-size: 14px;
    background-color: cadetblue;
    color: white;
    margin-right: 1rem;
    border-radius: 4px;
  }
  .flex-row {
    display: flex;
    width: 100%;
    flex-grow: 1;
  }

  .flex-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
  }
  .flex-body {
    padding-right: 1rem;
  }
  .grid {
    margin-bottom: 1rem;
  }
  .group-chart {
    background-color: white;
    position: relative;
    margin-bottom: 0.1rem;
    border: 1px solid lightgrey;
    padding: 10px 5px 10px 5px;
  }
`;