import React, {} from "react";
import NavBar from "../../shared/NavBar";
import Footer from "../../shared/Footer";
import { PO_DETAIL_API, SHIPMENT_STATUS_API } from "../../shared/Shared Utils/AppData";
import CustomDataGrid from "../../shared/Shared Utils/CustomDataTable";
import styled from "styled-components";
import moment from "moment";
import { INVOICE_TO_GNRF_SELECT_CONFIG, PO_PROCURER_SELECT_CONFIG, PROCURER_SELECT_CONFIG, QR_REPORT_DEFAULT_PARAM, STATE_SELECT_CONFIG } from "../../shared/Shared Utils/DataTableConstants";
import { FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";
import { FormatDecimal, getShortDate } from "../../../Utils/NumberUtils";
import { useEffect } from "react";
import { getEnvironemntList, getQuarterReportSummary } from "../../../Services/ApiManager";
import CustomCard from "../../shared/Shared Utils/CustomCards";

function getIndex(params) {
  return `${params.row.autoGeneratedId + 1}`;
}

function getDate(params) {
  return `${moment(params.row.IssueDate).format("MMMM Do, YYYY")}`;
}

function getPercentage(params) {
  return `${(Math.trunc(params.row.PAmountGRNFPrint*10))/10} %`;
}


const SHIPMENT_STATUS_COLUMN = [
  {
    field: "",
    renderHeader: (params) => <strong>{"SN"}</strong>,
    width: 100,
    valueGetter: getIndex,
  },
  {
    field: "PurchaseOrderNumber",
    renderHeader: (params) => <strong>{"PO Number"}</strong>,
    flex: 1,
  },
  {
    field: "PurchaseOrderType",
    renderHeader: (params) => <strong>{"PO Type"}</strong>,
    flex: 1,
  },
  {
    field: "PurchaseOrderDate",
    renderHeader: (params) => <strong>{"PO Date"}</strong>,
    flex: 1,
    valueGetter: getShortDate,
  },
  {
    field: "InvoiceNumber",
    renderHeader: (params) => <strong>{"Invoice Number"}</strong>,
    flex: 1,
  },
  {
    field: "ReceiptInvoiceDate",
    renderHeader: (params) => <strong>{"Invoice Date"}</strong>,
    flex: 1,
    valueGetter: getShortDate,
  },
  {
    field: "InvoiceGRNFGap",
    renderHeader: (params) => <strong>{"Invoice To GRNF"}</strong>,
    flex: 1,
  },
  {
    field: "PAmountGRNFPrint",
    renderHeader: (params) => <strong>{"GRNF"}</strong>,
    flex: 1,
    valueGetter: getPercentage,
  },
  {
    field: "PAmountGRVPrint",
    renderHeader: (params) => <strong>{"GRV"}</strong>,
    flex: 1,
    valueGetter: getPercentage,
  },
];

const SHIPMENT_STATUS_DEFAULT_PARAM = {
  ProcurerCode: 'PFSA',
  ModeCode: 'HPR',
  State: 'InProgress',
}

const PO_DETAIL_COLUMN = [
  {
    field: "PONo",
    renderHeader: (params) => <strong>{"PO No"}</strong>,
    width: 100,
  },
  {
    field: "TenderNumber",
    renderHeader: (params) => <strong>{"Tender No"}</strong>,
    width: 100,
  },
  {
    field: "ItemName",
    renderHeader: (params) => <strong>{"Item"}</strong>,
    width: 200,
  },
  {
    field: "Supplier",
    renderHeader: (params) => <strong>{"Supplier"}</strong>,
    width: 100,
  },
  {
    field: "TenderType",
    renderHeader: (params) => <strong>{"Tender Type"}</strong>,
    width: 100,
  },
  {
    field: "PODate",
    renderHeader: (params) => <strong>{"PO Date"}</strong>,
    width: 100,
    valueGetter: getDate,
  },
  {
    field: "POType",
    renderHeader: (params) => <strong>{"PO Type"}</strong>,
    width: 100,
  },
  {
    field: "FundingSource",
    renderHeader: (params) => <strong>{"Funding Source"}</strong>,
    width: 100,
  },
  {
    field: "Unit",
    renderHeader: (params) => <strong>{"Unit"}</strong>,
    width: 100,
  },
  {
    field: "Quantity",
    renderHeader: (params) => <strong>{"Quantity"}</strong>,
    width: 100,
  },
  {
    field: "UnitPrice",
    renderHeader: (params) => <strong>{"Unit Price"}</strong>,
    width: 100,
    valueGetter: (params) => `${FormatDecimal(params.row.UnitPrice, 0)}`,
  },
  {
    field: "TotalAmount",
    renderHeader: (params) => <strong>{"Total Amount"}</strong>,
    width: 125,
  },
  {
    field: "Currency",
    renderHeader: (params) => <strong>{"Currency"}</strong>,
    width: 100,
  },
  {
    field: "Manufacturer",
    renderHeader: (params) => <strong>{"Manufacturer"}</strong>,
    width: 100,
  },
  {
    field: "Country",
    renderHeader: (params) => <strong>{"Country"}</strong>,
    width: 100,
  },
  {
    field: "LocalAgent",
    renderHeader: (params) => <strong>{"Procurer"}</strong>,
    width: 100,
  },
];

const PO_DETAIL_DEFAULT_PARAM = {
}

const ShipmentStatus = () => {
  
  const [headerInfo, setHeaderInfo] = useState([]);
  const [environmentList, setEnvironmentList] = useState([]);
  const [modeCode, setModeCode] = useState("HPR");
  const [checked, setChecked] = useState(true);

  const onChangeDatePicker = (dates) => {
    Object.keys(dates).forEach((key) => {
      QR_REPORT_DEFAULT_PARAM[key] = dates[key];
    });
    handleChange(null, "Summary", QR_REPORT_DEFAULT_PARAM);
  };

  useEffect(() => {
    async function fetchData() {
      getEnvironemntList().then((data) => {
        setEnvironmentList([{Environment: "All", EnvironmentCode: ""}, ...data.data.Data]);
      });
      getQuarterReportSummary(loadFilterToDefaultParams(
        {...QR_REPORT_DEFAULT_PARAM, ModeCode: modeCode},
        { hasDateRangeFilter: true }
      )).then((data) => {
        setHeaderInfo(Object.fromEntries(data.data.Data.map(Object.values)));
      });
    }
    fetchData();
  }, [modeCode]);

  const handleChange = (event, key, chartFilterParams) => {
    key === "Summary" &&
      getQuarterReportSummary(
        chartFilterParams
      ).then((data) => {
        setHeaderInfo(Object.fromEntries(data.data.Data.map(Object.values)));
      });
  };

  function loadFilterToDefaultParams(filterParams, dateFilterFlag) {
    if (dateFilterFlag.hasDataFilter) {
      let currentDate = moment().subtract(30, 'day').format("MM/DD/YYYY");
      filterParams["TransactionDate"] = currentDate;
    }
    if (dateFilterFlag.hasDateRangeFilter) {
      const start = moment().subtract(3, 'months').format("MM/DD/YYYY");
      const end = moment().format("MM/DD/YYYY");
      filterParams["From"] = start;
      filterParams["To"] = end;
    }
    return filterParams;
  }

  return (
    <Wrapper>
      {" "}
      <NavBar title={`Fanos Shipment Tracking Dashboard`} />
      <div className="grid-section" style={{marginTop: "1rem"}}>
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <FormControlLabel control={
              <Switch
                defaultChecked={true} 
                onChange={(e)=> {
                  setChecked(e.target.checked);
                  e.target.checked? setModeCode('HPR'): setModeCode('RDF');
                }} />} 
              label={checked?"HPR": "RDF"} />
          <CustomDataGrid
            component_config={{
              width: "90vw",
              title: "Shipment Status:",
              data_source_api: SHIPMENT_STATUS_API,
              data_property: "Data",
              columns: SHIPMENT_STATUS_COLUMN,
              isSOH: false,
              isShipment: true,
              use_default_filter_params: false,
              select_filter_config: [
                INVOICE_TO_GNRF_SELECT_CONFIG,
                STATE_SELECT_CONFIG,
                PROCURER_SELECT_CONFIG,
              ],
              defaultFilterParams: {...SHIPMENT_STATUS_DEFAULT_PARAM, ModeCode: modeCode},
            }}
          />
        </div>
        <div className="grid" style={{padding: '0 5vw 1rem'}}>
          <CustomDataGrid
            component_config={{
              width: "90vw",
              title: "Purchase Order Detail Report",
              data_source_api: PO_DETAIL_API,
              data_property: "Data",
              columns: PO_DETAIL_COLUMN,
              isSOH: false,
              wgInfoId: 'wgCenterPoDetail',
              use_default_filter_params: false,
              select_filter_config: [
                PO_PROCURER_SELECT_CONFIG,
              ],
              defaultFilterParams: loadFilterToDefaultParams(
                PO_DETAIL_DEFAULT_PARAM,
                { hasDataFilter: true }
              ),
              date_picker: {
                date_range: false,
                start_date_param_name: "TransactionDate",
                start_date_param: "TransactionDate",
              },
            }}
          />
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
`;

export default ShipmentStatus;
