import React, { useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import Footer from "../../shared/Footer";
import PieChart from "../../shared/Charts/PieChart";
import BarChart from "../../shared/Charts/BarChart";
import styled from "styled-components";
import Nav from "react-bootstrap/Nav";
import {
  stockStatusColumn,
  recentReceivesColumn,
  issueHistoryColumn,
  pipelineReportColumn,
} from "./covidTableColumns";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbar,
} from "@mui/x-data-grid";
import Divider from "@mui/material/Divider";
import CustomPieChart from "../../shared/Charts/CustomPieChart";

import Select from "@mui/material/Select";
import "./covid19.css";

import { getEnvironemntList } from "../../../Services/ApiManager";
import {
  getDistributionByCommodityType,
  getDistributionByRegion,
} from "../../../Services/chartRequests";
import {
  getSOHByActivity,
  getPipelineReport,
  getRecentReceives,
  getIssueHistory,
} from "../../../Services/tableRequest";
import { useQuery } from "@tanstack/react-query";
import { Field, Formik } from "formik";
import ChartWrapper from "../../shared/Charts/ChartWrapper";
import { parseGridColumns, parseGridRows } from "../../../Utils/ChartConverter";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton style={{ color: "#15616d" }} />
      <GridToolbarFilterButton style={{ color: "#15616d" }} />
      {/* <GridToolbarDensitySelector style={{ color: "#15616d" }} /> */}
      <GridToolbarExport style={{ color: "#15616d" }} />
    </GridToolbarContainer>
  );
}

let filter = [];
const Covid19 = () => {
  const [cvdByRegion, setCvdByRegion] = useState([]);
  const [stockByActivity, setStockByActivity] = useState([]);
  const [issueHistory, setIssueHistory] = useState([]);
  const [recentReceives, setRecentReceives] = useState([]);
  const [pipelineReport, setPipelineReport] = useState([]);
  const {data: { data: { Data:  envCode }} = { data: { Data: [] }}, isFetching: envCodeLoading} = useQuery({
    queryKey: ['envCode'],
    queryFn: () => getEnvironemntList(),
    staleTime: 10800000,
  });
  const [cvdByCommodityParams, setCvdByCommodityParams] = useState({ModeCode: 'HPR'});
  const {data: { data: { Data:  cvdByCommodity }} = { data: { Data: [] }}} = useQuery({
    queryKey: ['cvdByCommodity', cvdByCommodityParams],
    queryFn: () => getDistributionByCommodityType(cvdByCommodityParams),
    staleTime: 10800000,
  });

  useEffect(() => {
    let mounted = true;
    getDistributionByRegion().then((data) => {
      if (mounted) {
        setCvdByRegion(data.data.Data);
      }
    });
    getSOHByActivity(filter).then((data) => {
      if (mounted) {
        setStockByActivity(data.data.Data);
      }
    });
    getPipelineReport(filter).then((data) => {
      if (mounted) {
        setPipelineReport(data.data.Data);
      }
    });
    getIssueHistory(filter).then((data) => {
      if (mounted) {
        setIssueHistory(data.data.Data);
      }
    });
    getRecentReceives(filter).then((data) => {
      if (mounted) {
        setRecentReceives(data.data.Data);
      }
    });
    return () => (mounted = false);
  }, []);

  //   useEffect(() => {
  //     let mounted = true;
  //     getDistributionByRegion().then((data) => {
  //       if (mounted) {
  //         setCvdByRegion(data.data.Data);
  //       }
  //     });
  //     return () => (mounted = false);
  //   }, []);

  //   useEffect(() => {
  //     let mounted = true;
  //     getSOHByActivity(filter).then((data) => {
  //       if (mounted) {
  //         setStockByActivity(data.data.Data);
  //       }
  //     });

  //     return () => (mounted = false);
  //   }, []);

  const handleChange = (event, key) => {
    key === "stockActivity" &&
      getSOHByActivity(event.target.value).then((data) => {
        setStockByActivity(data.data.Data);
      });
    key === "issueHistory" &&
      getSOHByActivity(event.target.value).then((data) => {
        setIssueHistory(data.data.Data);
      });
    key === "recentReceives" &&
      getSOHByActivity(event.target.value).then((data) => {
        setRecentReceives(data.data.Data);
      });
    key === "pipelineReport" &&
      getSOHByActivity(event.target.value).then((data) => {
        setPipelineReport(data.data.Data);
      });
  };

  return (
    <div>
      <NavBar title={`Fanos Covid 19 Dashboard`} />
      <Wrapper style={{ padding: "3rem" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Nav
            style={NavBarElements}
            activeKey="/Covid19"
          >
            <Nav.Item>
              <Nav.Link
                style={{
                  color: "#15616d",
                }}
                href="/covid19"
              >
                Covid 19 EPSA dashboard
              </Nav.Link>
            </Nav.Item>
            <Divider
              orientation="vertical"
              flexItem
              style={{ color: "black" }}
            />
          </Nav>
        </div>
        <div className="flex-box-container">
          <div className="flex-body">
            <ChartWrapper title="Covid19 Distribution by Commodity" wgInfoId="wgCovid19DistributionByType">
              <CustomPieChart
                data={cvdByCommodity}
                label={"covid distribution by commodity"}
                fields={["IssuedAmountInETB", "CommodityType"]}
                style={{
                  position: "relative",
                  width: "25rem",
                  height: "24rem",
                  background: "White",
                  padding: "0rem 1rem 1rem 1rem",
                }}
              />
              <DataGrid
                style={{
                  padding: "1rem",
                }}
                initialState={{pagination:{paginationModel:{pageSize:10, page:0}}}}
                autoHeight
                rows={parseGridRows(cvdByCommodity, ["CommodityType", "IssuedAmountInETB"])}
                columns={parseGridColumns(["CommodityType", "IssuedAmountInETB"])}
                getRowId={(row) => row.CommodityType}
                rowHeight={30}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 25]}
                pagination
                animateRows={true}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
              />
              <CustomPieChart
                data={cvdByCommodity}
                label={"covid distribution by commodity"}
                fields={["IssuedAmountInETB", "CommodityType"]}
                style={{
                  height:'15rem',
                  width: '32rem',
                  padding: "0rem 1rem 1rem 1rem",
                }}
              />
            </ChartWrapper>
          </div>
          <div className="flex-body">
            <BarChart data={cvdByRegion} label={"covid distribution by Region"} />
          </div>
        </div>
        <div className="table-spacing">
          <div className="row-flex">
            <h4 className="grey-color">Covid19 Stock Status: EPSA</h4>
            <div>
              <select
                className="select"
                onChange={(event) => handleChange(event, "stockActivity")}
              >
                <option value="" className="empty-option">
                  {"Home Office"}
                </option>
                {envCode.map((el) => (
                  <option key={el.EnvironmentCode} value={el.EnvironmentCode}>
                    {el.Environment}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <DataGrid
            autoHeight
            rows={stockByActivity}
            columns={stockStatusColumn}
            getRowId={(row) => row.ItemID}
            rowHeight={50}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            components={{ Toolbar: CustomToolbar }}
          />
        </div>
        <div className="table-spacing">
          <div className="row-flex">
            <h4 className="grey-color">Covid19 Issue History: EPSA</h4>
            <div>
              <select
                className="select"
                onChange={(event) => handleChange(event, "issueHistory")}
              >
                <option value="" className="empty-option">
                  {"Home Office"}
                </option>
                {envCode.map((el) => (
                  <option key={el.EnvironmentCode} value={el.EnvironmentCode}>
                    {el.Environment}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <DataGrid
            autoHeight
            rows={issueHistory}
            columns={issueHistoryColumn}
            getRowId={(row) => row.ReferenceNumber}
            rowHeight={50}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            components={{ Toolbar: CustomToolbar }}
          />
        </div>
        <div className="table-spacing">
          <div className="row-flex">
            <h4 className="grey-color">Covid19 Recent Receives</h4>
            <div>
              <select
                className="select"
                onChange={(event) => handleChange(event, "recentReceives")}
              >
                <option value="" className="empty-option">
                  {"Home Office"}
                </option>
                {envCode.map((el) => (
                  <option key={el.EnvironmentCode} value={el.EnvironmentCode}>
                    {el.Environment}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <DataGrid
            autoHeight
            rows={recentReceives}
            columns={recentReceivesColumn}
            getRowId={(row) => row.ItemID}
            rowHeight={50}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            components={{ Toolbar: CustomToolbar }}
          />
        </div>
        <div className="table-spacing">
          <div className="row-flex">
            <h4 className="grey-color">Covid19 Pipeline Report</h4>
            <div>
              <select
                className="select"
                onChange={(event) => handleChange(event, "pipelineReport")}
              >
                <option value="" className="empty-option">
                  {"Home Office"}
                </option>
                {envCode.map((el) => (
                  <option key={el.EnvironmentCode} value={el.EnvironmentCode}>
                    {el.Environment}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <DataGrid
            autoHeight
            rows={pipelineReport}
            columns={pipelineReportColumn}
            getRowId={(row) => row.ItemID}
            rowHeight={50}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            components={{ Toolbar: CustomToolbar }}
          />
        </div>
        <Footer />
      </Wrapper>
    </div>
  );
};

const NavBarElements = {
  borderBottomColor: "black",
  borderBottomWidth: 1,
  display: "flex",
  flexDirection: "row",
  flexWrap: "row",
  backgroundColor: "#fafafa",
  marginBottom: "30px",
  marginLeft: "0",
};

const Wrapper = styled.div`
  // display: flex;
  // flex-direction: row;
  // width: 10%;
  // height: auto';
  padding: "20px, 0";
  .flex-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
  }
  .flex-body {
    padding-right: 1rem;
  }
`;

export default Covid19;
